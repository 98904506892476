<template>
    <div class="error" v-if="!success && invoices.length == 0">
        <p style="font-weight: bold;">Please select at least 1 invoice to pay.</p>
    </div>
    <div class="ach-payment" v-if="!success && invoices.length > 0">
        <form @submit.prevent="formSubmit" enctype="multipart/form-data">
            <div class="loader" v-if="loading"></div>
            <div v-else>
                <div class="flex flex-wrap gap-4">
                    <Address
                        v-model:first-name="form.first_name"
                        v-model:last-name="form.last_name"
                        v-model:address="form.address"
                        v-model:address-two="form.address2"
                        v-model:city="form.city"
                        v-model:state="form.state"
                        v-model:zip="form.zip"
                    />
                    <div class="group flex-grow">
                        <label for="ach_type">
                            <span>Type*</span>
                            <select id="ach_type" class="field" v-model="form.ach_type">
                            <option value=""> -- </option>
                            <option value="1">Individual</option>
                            <option value="2">Company</option>
                            </select>
                        </label>
                        <label for="nameOnAccount"><span>Name on Account*</span>
                            <input type="text" name="nameOnAccount" v-model="form.name_on_account" placeholder="Name on your Checking Account"  class="field" required>
                        </label>
                        <label>
                            <span>Routing number*</span>
                            <input id="routing-number" class="field" name="routing_number" v-model="form.routing_number" placeholder="110000000" />
                        </label>
                        <label>
                            <span>Account number*</span>
                            <input id="account-number" class="field" name="account_number" v-model="form.account_number" placeholder="000123456789" />
                        </label>
                    </div>
                </div>
                <div class="group flex flex-grow total-section justify-between align-center">
                    <div>
                        <span>Total {{formatMoney(invoicesTotal)}}</span><br>
                        <em>(Reflects $4 flat fee)</em>
                    </div>
                    <button class="btn-blue flex-grow-0">Pay Now</button>
                </div>
            </div>
        </form>
    </div>
    <div class="success" v-if="processed && success">
        <h3>Thank you for your payment!</h3>
    </div>
    <div class="error" style="font-weight: bold;" v-if="processed && !success">
        <h3>Payment Declined.</h3>
        <p>There was a problem with trying to submit your payment.</p>
        <p>Please check your routing number and account number.</p>
    </div>
</template>

<script>
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Address from './Address.vue'

    export default {
        mixins: [formattingMixin],
        components: {
            Address,
        },
        emits: ['refresh'],
        props: ['invoices'],
        data() {
            return {
                loading: false,
                success: false,
                processed: false,
                form: {
                    payment_type  : 'ACH',
                    entity_ids    : this.$page.props.user.login_entity_ids,
                    first_name    : '',
                    last_name     : '',
                    address       : '',
                    address2      : '',
                    city          : '',
                    state         : '',
                    zip           : '',
                    ach_type      : '',
                    routing_number: '',
                    account_number: '',
                    total         : '',
                    subtotal      : '',
                    allInvoices   : [],
                    nameOnAccount : '',
                }
            }
        },
        computed: {
            invoicesTotal() {
                var total = 0.00;
                for (const listItem of this.invoices) {
                    total = total + listItem.Posted_Balance;
                }
                this.subtotal = total;
                return parseFloat(total) + 4.00;
            }
        },
        methods: {
            formSubmit() {
                this.loading = true;
                this.form.total = this.subtotal;
                this.form.allInvoices = this.invoices;
                axios.post('/payment', this.form)
                    .then( response =>  {
                        if(response.data.success){
                            this.loading = false;
                            this.success = true;
                            this.processed = true;
                            this.$emit('refresh', true);
                        } else {
                            this.loading = false;
                            this.success = false;
                            this.processed = true;
                        }
                })
            }
        }
    }
</script>

<style lang="scss">
.ach-payment{

    form {
        display: flex;
        justify-content: space-between;
    }
    .total-section {
        padding: 20px;
    }
    .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
                0 3px 6px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    margin-bottom: 20px;
    }

    label {
    position: relative;

    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    }

    .group label {
    border-bottom: 2px solid #F0F5FA;
    }

    label > span {
    width: 140px;
    text-align: left;
    }

    .field {
    background: transparent;
    font-weight: 300;
    border: 0;

    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
    }

    .field::-webkit-input-placeholder { color: #CFD7E0; }
    .field::-moz-placeholder { color: #CFD7E0; }
}

</style>
