<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
        </div>
        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p class="st-header" :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p class="st-header" :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p class="st-header last-col">Send Invite</p>

            <template v-for="row in displayedList" :key="'search_and_invite_' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.first_name }} {{ row.last_name }}</p>
                <p><span class="st-hidden">Email</span>{{ row.email }}</p>
                <p><span class="st-hidden">Role Type</span>{{ row.role_type }}</p>
                <div class="last-col">
                    <span class="st-hidden">Invitation Date</span>
                    <div class="st-buttons">
                        <button @click="emitInvitation(row)" class="btn-green">Send Invitation</button>
                    </div>
                </div>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin],
        components: {
            SimpleTableWrapper
        },
        methods: {
            emitInvitation(row) {
                this.$emit('send-invitation', {
                    first_name:row.first_name,
                    last_name:row.last_name,
                    email:row.email,
                    role_type:row.role_type
                })
            }
        }
    }
</script>