<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Policy No.</p>
            <p :class="sortClass('insured_name')" @click="sortBy('insured_name')" v-if="$page.props.user.role_type!='CN'">Insured</p>
            <p :class="sortClass('Contract_Set_Status')" @click="sortBy('Contract_Set_Status')">Status</p>
            <p :class="sortClass('Line_Code')" @click="sortBy('Line_Code')">Line of Business</p>
            <p :class="sortClass('Full_Term_Prem')" @click="sortBy('Full_Term_Prem','number')">Written Premium</p>
            <p :class="sortClass('Eff_Date')" @click="sortBy('Eff_Date','date')">Effective Date</p>
            <p :class="sortClass('Exp_Date')" @click="sortBy('Exp_Date','date')">Expiration</p>
            <p></p>
        </div>
        <div class="simple-table" :style="[$page.props.user.role_type!='CN' ? '--cols:8' : '--cols:7']">
            <p class="st-header first-col" :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Policy No.</p>
            <p class="st-header" :class="sortClass('insured_name')" @click="sortBy('insured_name')" v-if="$page.props.user.role_type!='CN'">Insured</p>
            <p class="st-header" :class="sortClass('Contract_Set_Status')" @click="sortBy('Contract_Set_Status')">Status</p>
            <p class="st-header" :class="sortClass('Line_Code')" @click="sortBy('Line_Code')">Line of Business</p>
            <p class="st-header" :class="sortClass('Full_Term_Prem')" @click="sortBy('Full_Term_Prem','number')">Written Premium</p>
            <p class="st-header" :class="sortClass('Eff_Date')" @click="sortBy('Eff_Date','date')">Effective Date</p>
            <p class="st-header" :class="sortClass('Exp_Date')" @click="sortBy('Exp_Date','date')">Expiration</p>
            <p class="st-header last-col">Quick Actions</p>

            <template v-for="(row,index) in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col">
                    <span class="st-hidden">Policy No.</span>
                    <Link class="underline" :href="route('policies.details', row.Contract_ID)">{{ row.Contract_Num }}</Link>
                </p>
                <p v-if="$page.props.user.role_type!='CN'"><span class="st-hidden">Insured</span>{{ row.insured_name }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.Contract_Set_Status" /></p>
                <p><span class="st-hidden">Line of Business</span>{{ row.Line_Code }}</p>
                <p><span class="st-hidden">Written Premium</span>{{ formatMoney(row.Full_Term_Prem) }}</p>
                <p><span class="st-hidden">Effective Date</span>{{ formatDate(row.Eff_Date) }}</p>
                <p><span class="st-hidden">Expiration</span>{{ formatDate(row.Exp_Date) }}</p>
                <p v-if="row.Market_Type == 'G' || !row.Contract_Set_Status == 'Expired'" class="last-col expand-details" @click="setActivePolicy(row.Contract_ID, index)">
                    <span class="st-hidden">+/-</span>
                    <FontAwesomeIcon v-if="activePolicy != row.Contract_ID" class="ml-4" :icon="plus"/>
                    <FontAwesomeIcon v-if="activePolicy == row.Contract_ID" class="ml-4" :icon="minus"/>
                </p>
                <p v-else class="last-col"></p>
            </template>
            <div class="bg-gray-100 dropdown-panel" :class="[activePolicy?'active':'',activePolicyLoading?'loading':'']" :style="getGridArea">
                <PoliciesTableActionsDropdown :data="activePolicyDetails" />
                <div class="loader" v-if="activePolicyLoading"></div>
            </div>
        </div>
    </SimpleTableWrapper>

</template>

<script>
    import PoliciesTableActionsDropdown from '../Partials/PoliciesTableActionsDropdown.vue'
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faMinus } from '@fortawesome/free-solid-svg-icons'
    import { faPlus } from '@fortawesome/free-solid-svg-icons'
    import formattingMixin from '@/Mixins/formattingMixin.js'

    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'
    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            PoliciesTableActionsDropdown,
            SimpleTableWrapper,
            FontAwesomeIcon,
            Status,
            Link,
        },
        data() {
            return {
                plus: faPlus,
                minus: faMinus,
                activePolicy: null,
                activePolicyDetails: null,
                activePolicyLoading: false,
                activePolicyIndex: null,
                claimServiceModalVisible: false,
            }
        },
        watch: {
            activePolicy(n) {
                if (n) {
                    this.activePolicyLoading = true;
                    this.activePolicyDetails = null;

                    this.loading = true,
                    axios.post('/policies/pdata',
                        {
                            'entity_id': n
                        })
                        .then( response =>  {
                            this.activePolicyLoading = false,
                            this.activePolicyDetails = response.data.pdata

                    })

                    this.activePolicyLoading = false,
                    this.activePolicyDetails = n

                } else {
                    this.activePolicyDetails = null;
                }
            },
            displayedList: {
                handler() {
                    this.activePolicy = null;
                    this.activePolicyDetails = null;
                },
                deep: true
            }
        },
        computed: {
            getGridArea() {
                return `--grid-position:${this.activePolicyIndex+3};--grid-mobile-position: ${(this.activePolicyIndex*7)+4}`;
            },
        },
        mounted:function(){
            this.sortBy('Contract_Set_Status')
        },
        methods: {
            setActivePolicy(policyId, index) {
                this.activePolicy = this.activePolicy !== policyId ? policyId : null;
                this.activePolicyIndex = index;
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), 1fr);
    }

    .dropdown-panel {
        position: relative;
        overflow: hidden;
        transition: 200ms ease-out;
        display: block !important;
        grid-column: 1 / -1;

        @include bp-up($bp-xl + 1) { grid-row: var(--grid-position) / span 1; }
        @include bp-down($bp-xl) { grid-row: var(--grid-mobile-position) / span 1; }

        &.loading {
            padding-top: 50px !important;
            padding-bottom: 50px !important;
        }

        &:not(.active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            opacity: 0;
        }

        &.active {
            height: auto;
            padding: 1rem;
            box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
    }
    .expand-details {
        cursor: pointer;
    }
</style>
