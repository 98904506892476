<template>
    <AppLayout title="Policies">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'My View','Policies']"/>
        </template>

        <PoliciesSection :entity-id="entityId"/>
    </AppLayout>
</template>

<script>
    import PoliciesSection from './Sections/PoliciesSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        components: {
            PoliciesSection,
            Breadcrumbs,
            AppLayout,
        },
        computed: {
            entityId() {
                //GET ID FROM PATH
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var segment2 = parseInt(segment_array.pop());
                var segment1 = segment_array.pop();
                var entityId = segment2;
                return entityId;
            }
        }
    }
</script>
