<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="p-2">
            <label for="show_accepted">Show Accepted Invitations <input type="checkbox" name="show_accepted" v-model="show_accepted" @click="fetchAcceptedInvitations"></label>
        </div>
        <div class="st-hidden-sorts">
            <p :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p :class="sortClass('created_at')" @click="sortBy('created_at','date')">Invitation Date</p>
        </div>
        <div class="simple-table" style="--cols:5">
            <p class="st-header first-col" :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p class="st-header" :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p class="st-header" :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p class="st-header" :class="sortClass('created_at')" @click="sortBy('created_at','date')">Invitation Date</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="row in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.first_name }} {{ row.last_name }}</p>
                <p><span class="st-hidden">Email</span>{{ row.email }}</p>
                <p><span class="st-hidden">Role Type</span>{{ row.role_type }}</p>
                <p><span class="st-hidden">Invitation Date</span>{{ formatDate(row.created_at) }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <button @click="deleteUserInvitation(row)" class="btn-red">Delete</button>
                    </div>
                </div>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import formattingMixin from '@/Mixins/formattingMixin.js';
    import tableMixin from '@/Mixins/tableMixin.js';
    import moment from 'moment';

    export default {
        mixins: [tableMixin, formattingMixin],
        components: {
            SimpleTableWrapper
        },
        data() {
            return {
                moment: moment,
                show_accepted: false,
            }
        },
        methods: {
            deleteUserInvitation(row) {
                this.$emit('delete-invitation', row);
            },
            fetchAcceptedInvitations() {
                if(!this.show_accepted){
                    this.$emit('fetch-accepted-invitations');
                } else {
                    this.$emit('fetch-user-invitations');
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
