<template>
    <AppLayout title="Policy Details">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'Policies',route:'policies.policies'},`Policy Number: ${pdata.policy_number}`]"/>
        </template>

        <div class="lg:flex -m-4">
            <div class="lg:w-2/3 2xl:w-3/4">
                <div class="p-4">
                    <PolicyDetailsSection :pdata="pdata"/>
                </div>

                <div class="p-4" v-if="pdata.documentsInfo.length">
                    <Card color="teal" title="Documents">
                        <PolicyDetailsDocumentsTable
                            :cols="3"
                            :data="pdata.documentsInfo"
                        />
                    </Card>
                </div>

                <div class="p-4" v-if="pdata.certificatesInfo.length">
                    <Card color="teal" title="Certificates">
                        <PolicyDetailsCertificatesTable
                            :cols="3"
                            :data="pdata.certificatesInfo"
                        />
                    </Card>
                </div>
                <div class="p-4" v-if="!pdata.documentsInfo.length && !pdata.certificatesInfo.length">
                    <ClaimsSection :claims_data="pdata.claimsList.search_results"/>
                </div>
            </div>
            <div class="lg:w-1/3 2xl:w-1/4">
                <div class="p-4">
                    <YourAccountManagerSection :pdata="pdata"/>
                </div>

                <div class="p-4">
                    <SubmitClaimSection :pdata="pdata"/>
                </div>

                <div class="p-4">
                    <ClaimServicingSection :pdata="pdata" />
                </div>
            </div>
        </div>

        <div class="pt-4" v-if="pdata.documentsInfo.length || pdata.certificatesInfo.length">
           <ClaimsSection :claims_data="pdata.claimsList.search_results"/>
        </div>
   </AppLayout>
</template>

<script>
    import PolicyDetailsCertificatesTable from './Tables/PolicyDetailsCertificatesTable.vue'
    import PolicyDetailsDocumentsTable from './Tables/PolicyDetailsDocumentsTable.vue'
    import YourAccountManagerSection from './Sections/YourAccountManagerSection.vue'
    import ClaimServicingSection from './Sections/ClaimServicingSection.vue'
    import PolicyDetailsSection from './Sections/PolicyDetailsSection.vue'
    import SubmitClaimSection from './Sections/SubmitClaimSection.vue'
    import ClaimsSection from '../Sections/ClaimsSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            PolicyDetailsCertificatesTable,
            PolicyDetailsDocumentsTable,
            YourAccountManagerSection,
            ClaimServicingSection,
            PolicyDetailsSection,
            SubmitClaimSection,
            ClaimsSection,
            Breadcrumbs,
            JetCheckbox,
            AppLayout,
            JetLabel,
            JetInput,
            Link,
            Card,
        },
        props: {
            pdata: Object,
            claims_data:[]
        },
        methods: {
            openModal() {
                this.showActMgrModal = true;
            },
            closeModal() {
                this.isOpen = false;
                this.reset();
                this.showActMgrModal = false;
            },
            reset() {
                this.form = {
                    your_name: null,
                    email: null,
                    mobile: null,
                    message: null,
                    account_manager_email:null,
                    account_manager_name:null
                }
            }
        }
    }
</script>
