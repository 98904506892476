<template>
    <div class="policy-details-wrappper px-6 py-4 bg-gray-300" v-if="data">
        <div class="flex flex-wrap items-start">
            <h3 class="quick-actions-banner text-center">Quick Actions</h3>
            <div class="mt-5 action-buttons gap-4">
                <button class="btn btn-blue action-button" @click="payBill()">PAY MY BILL</button>
                <button class="btn btn-blue action-button" @click.self="claimServiceModalVisible = true">SUBMIT A CLAIM</button>
                <button class="btn btn-blue action-button" @click.self="additionalCertificateHolderModalVisible = true">REQUEST CERT</button>
                <button class="btn btn-blue action-button" @click.self="lossRunModalVisible = true">REQUEST LOSS RUN</button>
                <button class="btn btn-blue action-button" @click.self="otherPolicyRequestModalVisible = true">CHANGE POLICY</button>
                <button class="btn btn-blue action-button" @click="goToDocs()">DOCUMENTS</button>
            </div>
        </div>
    </div>
    <SimpleModal :visible="claimServiceModalVisible" @on-close="closeModal()" width="1000px">
        <SubmitClaimModalContent @on-close="closeModal()" :reset-form-trigger="resetFormTrigger" :pdata="data"/>
    </SimpleModal>
    <SimpleModal :visible="lossRunModalVisible" @on-close="lossRunModalClose()" width="800px">
        <LossRunModalContent :visible="lossRunModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="lossRunModalClose()" :pdata="data" />
    </SimpleModal>
    <SimpleModal :visible="otherPolicyRequestModalVisible" @on-close="otherPolicyRequestModalClose()" width="800px">
        <OtherPolicyRequestModalContent :visible="otherPolicyRequestModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="otherPolicyRequestModalClose()" :pdata="data" />
    </SimpleModal>
    <SimpleModal :visible="additionalCertificateHolderModalVisible" @on-close="additionalCertificateHolderModalClose()" width="800px">
        <AdditionalCertificateHolderModalContent :visible="additionalCertificateHolderModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="additionalCertificateHolderModalClose()" :pdata="data" />
    </SimpleModal>
</template>

<script>
import AdditionalCertificateHolderModalContent from '../Details/Sections/AdditionalCertificateHolderModalContent.vue'
import OtherPolicyRequestModalContent from '../Details/Sections/OtherPolicyRequestModalContent.vue'
import SubmitClaimModalContent from '../Details/Sections/SubmitClaimModalContent.vue'
import LossRunModalContent from '../Details/Sections/LossRunModalContent.vue'
import formattingMixin from '@/Mixins/formattingMixin.js'
import SimpleModal from '@/Components/SimpleModal.vue'
import { Link } from '@inertiajs/inertia-vue3'
import JetLabel from '@/Jetstream/Label.vue'
import Status from '@/Components/Status.vue'

export default {
    mixins: [formattingMixin],
    props: ["data"],
    components: {
        AdditionalCertificateHolderModalContent,
        OtherPolicyRequestModalContent,
        SubmitClaimModalContent,
        LossRunModalContent,
        SimpleModal,
        JetLabel,
        Status,
        Link,
    },
    data() {
        return {
            additionalCertificateHolderModalVisible: false,
            claimServiceModalVisible      : false,
            lossRunModalVisible           : false,
            resetFormTrigger              : false,
            otherPolicyRequestModalVisible: false,
        }
    },
    methods: {
        getData(prop) {
            return this.data?.[prop] || null;
        },
        getPData(id) {

        },
        openModal() {
            this.claimServiceModalVisible = true;
        },
        closeModal() {
            this.isOpen = false;
            this.reset();
            this.claimServiceModalVisible = false;
        },
        reset() {
            this.form = {
                your_name: null,
                email: null,
                mobile: null,
                message: null,
                account_manager_email:null,
                account_manager_name:null
            }
        },
        payBill() {
            let baseUrl   = '/pay-my-bill';
            window.open(baseUrl, "_self");
        },
        lossRunModalClose() {
            this.lossRunModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        otherPolicyRequestModalClose() {
            this.otherPolicyRequestModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        additionalCertificateHolderModalClose() {
            this.additionalCertificateHolderModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        goToDocs(){
            window.location.href="/policies/" + this.data.pdata.contract_id;
        }
    },
}
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .quick-actions-banner { width: 100%; }
    .action-buttons {
        width: 100%;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        justify-content: center;
        .btn {
            width: fit-content;
        }
    }
</style>
