<template>
    <div class="file-uploader-wrapper">
        <input hidden type="file" @change.prevent="onInput($event)" ref="fileInput" accept=".jpg, .jpeg, .png, .gif, .pdf, .doc, .docx, .ppt, .pptx, .xl, .xls, .xlsx, .cs, .tx, .txt, .odt, .avi, .ogg, .m4a, .mov, .mp3, .mp4, .mpg, .wav, .wm, .zip"  v-bind="$attrs" multiple/>
        <div class="file-list">
            <p class="file" v-for="(file, i) in files" :key="i+'file'">
                {{ file.name }}
                <FontAwesomeIcon :icon="trashAlt" @click.prevent="removeFile(i)" style="color: var(--red)" />
            </p>
        </div>
        <div class="buttons">
            <button class="btn-outline-gray" @click.prevent="chooseFiles()">Add Files</button>
        </div>
        <div class="file-types">
            <p><em><strong>Available file types:</strong> jpg, jpeg, png, gif, pdf, doc, docx, ppt, pptx, xl, xls, xlsx, cs, tx, txt, odt, avi, ogg, m4a, mov, mp3, mp4, mpg, wav, wmv, zip</em></p>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

export default {
    inheritAttrs: false,
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components: {
        FontAwesomeIcon,
    },
    data() {
        return {
            files: [],
            trashAlt: faTrashAlt
        }
    },
    watch: {
        files: {
            handler(n) {
                this.$emit('update:modelValue', n);
            },
            deep: true
        }
    },
    methods: {
        onInput() {
            this.files = this.files.length ? this.files.concat([...event.target.files]) : [...event.target.files];
        },
        removeFile(i) {
            this.files.splice(i ,1);
        },
        chooseFiles() {
            this.$refs.fileInput.click();
        },
    }
}
</script>

<style lang="scss" scoped>
    .file-uploader-wrapper {
        padding: .25rem .5rem;
        background-color: hsl(0deg,0%,95%);
        border-radius: 5px;
        margin-top: .25rem;
    }

    .file-list {
        .file {
            padding: 2px 4px;
            background-color: white;
            font-size: 12px;
            margin: .25rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            word-break: break-word;

            svg {
                margin-left: .5rem;
                cursor: pointer;
            }
        }
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.25rem;

        button {
            margin: .25rem;
        }
    }
</style>
