<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p :class="sortClass('status_msg')" @click="sortBy('status_msg')">Status</p>
        </div>
        <div class="simple-table" style="--cols:4">
            <p class="st-header first-col" :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p class="st-header" :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p class="st-header" :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p class="st-header last-col" :class="sortClass('status_msg')" @click="sortBy('status_msg')">Status</p>

            <template v-for="row in displayedList" :key="'import_users_' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.first_name }} {{ row.last_name }}</p>
                <p><span class="st-hidden">Email</span>{{ row.email }}</p>
                <p><span class="st-hidden">Role Type</span>{{ row.role_type }}</p>
                <p class="last-col"><span class="st-hidden">Status</span>{{ row.status_msg }}</p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin],
        components: {
            SimpleTableWrapper
        }
    }
</script>
