<template>
    <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
        <div class="loader" v-if="loading"></div>
        <template v-else>
            <h3 class="text-blue-500">Other Policy Request </h3>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-500 my-0"> {{validate_msg}}</p>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow">
                    <JetLabel for="requester_name" value="Your Name" />
                    <JetInput name="requester_name" type="text" class="mt-1 block w-full" v-model="form.requester_name" required/>
                </div>

                <div class="m-2 flex-grow">
                    <JetLabel for="requester_email" value="Email" />
                    <JetInput name="requester_email" type="email" class="mt-1 block w-full" v-model="form.requester_email" required/>
                </div>

                <div class="m-2 flex-grow">
                    <JetLabel for="policy_number_or_type" value="Policy Number/Type " />
                    <JetInput name="policy_number_or_type" type="text" class="mt-1 block w-full" />
                </div>
            </div>

            <div class="mt-2">
                <JetLabel for="policy_description" value="Policy Description " />
                <textarea name="policy_description" class="flex-grow border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full" placeholder="Enter Description"></textarea>
            </div>

            <div class="mt-2">
                <JetLabel for="other_policy_request_cdocument" value="Upload Document" />
                <SimpleFileUploader id="other_policy_request_cdocument" v-model="form.files" />
            </div>

            <div class="flex flex-wrap mt-2 -mx-2 -mb-2">
                <button class="btn-green m-2">Submit </button>
                <button @click.prevent="closeModal()" class="btn-outline-gray m-2">Cancel</button>
                <button @click.prevent="reset()" class="btn-outline-gray m-2">Reset</button>
            </div>
        </template>
    </form>
</template>

<script>
import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue'
import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'

export default {
    mixins: [formProcessingMixin],
    props: ["pdata", "resetFormTrigger", "visible"],
    components: {
        SimpleFileUploader,
        SimpleDate,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            // just for passing user data by default, not for using with submission
            form: {
                requester_name: '',
                requester_email: '',
                files: []
            },
            validate_msg: '',
            loading: false,
        }
    },
    methods: {
        submitForm(event) {
            this.submit(event, 'submitOtherPolicyRequestForm', this.additionalData, {name: 'files', list: this.form.files});
        },
        initWithUserData() {
            let u = this.$page.props.user;
            this.form.requester_name = `${u.first_name} ${u.last_name}`;
            this.form.requester_email = u.email;
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'account_manager_email', value: this.pdata.account_manager_email },
                { key: 'account_manager_name',  value: this.pdata.account_manager_name },
                { key: 'insured_name',          value: this.pdata.insured_name }
            ]
        },
    },
    watch: {
        resetFormTrigger() {
            this.reset();
        },
        visible(n) {
            if (n) this.initWithUserData();
        }
    }
}
</script>