export default {
    emits: ['on-close'],
    methods: {
        // additionalData format: [{key:String,value:String}]
        submit(event, url, additionalData, filesData) {
            let data = new FormData(event.target);
            if (additionalData) {
                additionalData.forEach(d => {
                    data.set(d.key, d.value)
                })
            }

            //For file upload I am unable to use above section because that is giving only string data.
            //Files data contains an array of both filename and filecontent.
            if(filesData) {
                filesData.list.forEach((file,i) => {
                    data.set(`${filesData.name}[${i}]`,file);
                })
            }

            let vm = this;
            vm.loading = true;
            axios.post(route(url), data)
                .then(res => {
                    vm.error_msg = false;
                    vm.validate_msg = res.data.message;
                    vm.loading = false;
                    vm.reset();
                    setTimeout(() => vm.validate_msg = false, 20000)
                }).catch(error => {
                    vm.validate_msg = false;
                    vm.loading = false;
                    vm.error_msg = error.response.data.message;                  
                });
        },
        reset(orig) {
            this.$refs.form.reset();

            // if there are form files, remove them
            if (this.form?.files?.length) this.form.files.splice(0,this.form.files.length);

            if (orig && Object.keys(orig).length) Object.entries(orig).forEach(([k,v])=>this.$data.form[k] = v);
            else {
                try {
                    let prevData = this.$options.data().form;
                    Object.assign(this.$data.form, prevData);
                } catch(err) {
                    console.warn('Unreadable value in original data, leaving be for now');
                    console.warn(err);
                }
            }
        },
        isValidEmail(email) {
            let regex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            return regex.test(email);
        },
        closeModal() {
            this.reset();
            this.$emit('on-close');
        }
    }
}