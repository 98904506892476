<template>
    <form enctype="multipart/form-data" ref="form" @submit.prevent="submit($event, 'submitContactAccountManagerForm',additionalData)">
        <div class="loader" v-if="loading"></div>
        <template v-else>
            <h3 class="text-blue-500">Contact <strong>{{ pdata.account_manager_name }}</strong></h3>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-500 my-0"> {{validate_msg}}</p>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="flex-grow m-2">
                    <JetLabel for="your_name" value="Your Name" />
                    <JetInput id="your_name" name="your_name" type="text" class="mt-1 block w-full" v-model="form.requester_name" required />
                </div>

                <div class="flex-grow m-2">
                    <JetLabel for="email" value="Email" />
                    <JetInput id="email" name="email" type="email" class="mt-1 block w-full" v-model="form.requester_email" required />
                </div>

                <div class="flex-grow m-2">
                    <JetLabel for="mobile" value="Mobile" />
                    <JetInput id="mobile" type="text" name="mobile" class="mt-1 block w-full" required />
                </div>
            </div>

            <div class="mt-2">
                <JetLabel for="message" value="Message" />
                <textarea name="message" class="flex-grow border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full"  placeholder="Enter Message" ></textarea>
            </div>

            <div class="flex flex-wrap mt-2 -mx-2 -mb-2">
                <button class="btn-green m-2">Contact Me</button>
                <button @click.prevent="closeModal()" class="btn-outline-gray m-2">Cancel</button>
                <button @click.prevent="reset()" class="btn-outline-gray m-2">Reset</button>
            </div>
        </template>
    </form>
</template>

<script>
import AddressInputGroup from '@/Components/AddressInputGroup.vue'
import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'

export default {
    mixins: [formProcessingMixin],
    props: ["pdata", "resetFormTrigger", "visible"],
    components: {
        AddressInputGroup,
        SimpleDate,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            form: {
                requester_name: '',
                requester_email: '',
            },
            validate_msg: '',
            loading: false,
        }
    },
    methods: {
        initWithUserData() {
            let u = this.$page.props.user;
            this.form.requester_name = `${u.first_name} ${u.last_name}`;
            this.form.requester_email = u.email;
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'account_manager_email', value: this.pdata.account_manager_email },
                { key: 'account_manager_name',  value: this.pdata.account_manager_name },
                { key: 'insured_name',          value: this.pdata.insured_name }
            ]
        }
    },
    watch: {
        resetFormTrigger() {
            this.reset();
        },
        visible(n) {
            if (n) this.initWithUserData();
        }
    }
}
</script>