<template>
    <div class="footer" ref="footer">
        <div class="footer-cols">
            <div class="footer-col logo-col">
                <div class="logo">
                    <Link :href="route('dashboard')">
                        <jet-application-logo reverse />
                    </Link>
                </div>
                <div class="socials">
                    <a href="https://www.facebook.com/VGMInsurance/" :title="title('Facebook')" target="_blank"><FontAwesomeIcon :icon="facebook"/></a>
                    <a href="https://www.linkedin.com/company/vgm-insurance/" :title="title('LinkedIn')" target="_blank"><FontAwesomeIcon :icon="linkedin"/></a>
                    <a href="https://www.youtube.com/channel/UC5gOQ9SZHkeR-hUzZYObHOg" :title="title('YouTube')" target="_blank"><FontAwesomeIcon :icon="youtube"/></a>
                </div>
            </div>
            <div class="footer-col">
                <h3 class="heading">Get In Touch</h3>
                <a href="tel:8667793461" title="Opens our main contact phone number in your default calling application">866-779-3461</a>
                <a href="mailto:info@vgminsurance.com" title="Starts a new email conversation with our main contact email in your default mailing application">info@vgminsurance.com</a>
                <a :href="vins('who-we-are')" :title="title('Who We Are')" target="_blank">Who We Are</a>
                <a :href="vins('contact-us')" :title="title('Contact Us')" target="_blank">Send Us a Message</a>
            </div>
            <div class="footer-col programs-col">
                <h3 class="heading">Programs</h3>
                <div class="links">
                    <div>
                        <a :href="programs('home-medical-equipment')" :title="title('Home Medical Equipment')" target="_blank">Home Medical Equipment</a>
                        <a :href="programs('physical-therapy')" :title="title('Physical Therapy')" target="_blank">Physical Therapy</a>
                        <a :href="programs('medical-manufacturer-reps-distributors')" :title="title('Medical Manufacturer Reps')" target="_blank">Medical Manufacturer Reps</a>
                        <a :href="programs('additional-allied-healthcare-insurance')" :title="title('All Allied Healthcare')" target="_blank">All Allied Healthcare</a>
                    </div>
                    <div>
                        <a :href="programs('home-health-hospice')" :title="title('Home Health &amp; Hospice')" target="_blank">Home Health &amp; Hospice</a>
                        <a :href="programs('orthotic-prosthetic')" :title="title('Orthotic &amp; Prosthetic')" target="_blank">Orthotic &amp; Prosthetic</a>
                        <a :href="programs('golf-insurance')" :title="title('Golf Insurance')" target="_blank">Golf Insurance</a>
                        <a :href="vins('vgmbonds')" :title="title('Bonds')" target="_blank">Bonds</a>
                    </div>
                </div>
            </div>
            <div class="footer-col">
                <h3 class="heading">Resources</h3>
                <a target="_blank" :href="resources('risk-management')" :title="title('Risk Management')">Risk Management</a>
                <a target="_blank" :href="resources('videos')" :title="title('Videos')">Videos</a>
                <a target="_blank" :href="resources('online-education')" :title="title('Online Education')">Online Education</a>
                <a target="_blank" :href="vins('blog')" :title="title('View Our Blog')">View Our Blog</a>
            </div>
        </div>
        <div class="copyright">
            <p>
                <a :href="vins('privacy-policy')" :title="title('Privacy Policy')" target="_blank">Privacy Policy</a> |
                <a :href="vins('sitemap')" :title="title('Sitemap')" target="_blank">Sitemap</a> |
                <a :href="vins('terms-of-use')" :title="title('Terms of Use')" target="_blank">Terms of Use</a> |
                <a :href="vins('web-content-accessibility')" :title="title('Website Accessibility Notice')" target="_blank">Website Accessibility Notice</a>
            </p>
            <p>&copy; VGM Insurance. All Rights Reserved. Custom web solutions provided by <a href="https://goo.gl/Rwf1Zx" title="External link to VGM Forbin's site in a new window" target="_blank">VGM Forbin</a></p>
        </div>
    </div>
</template>

<script>
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faFacebook, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
    import { Link } from '@inertiajs/inertia-vue3'

    export default {
        name: "AppFooter",
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            Link
        },
        data() {
            return {
                facebook: faFacebook,
                linkedin: faLinkedin,
                youtube: faYoutube,
            }
        },
        methods: {
            getFooterHeight() {
                if (this.$refs.footer) {
                    document.body.style.setProperty('--footer-height', this.$refs.footer.clientHeight + 'px');
                }
            },
            vins(url) {
                return `https://www.vgminsurance.com/${url}`;
            },
            programs(url) {
                return this.vins(`programs/${url}`);
            },
            resources(url) {
                return this.vins(`resources/${url}`);
            },
            title(pageName) {
                return `External link to our ${pageName} page in a new tab`;
            }
        },
        mounted() {
            this.getFooterHeight();
            window.addEventListener('resize', this.getFooterHeight);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.getFooterHeight);
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .footer {
        z-index: 1;
        grid-area: footer;
        background-color: var(--blue-500);
        color: white;

        @include not-desktop { padding-bottom: 40px; }

        .socials {
            display: flex;

            :deep(a) {
                @include fluid-size(22px, 25px, font-size);
                padding: .5rem !important;
            }
        }

        .footer-cols {
            @include fluid-size(32px, 48px, padding);
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;

            @include bp-up($bp-lg + 1) {
                display: flex;
                justify-content: space-between;
            }

            .footer-col {
                display: flex;
                flex-direction: column;

                @include bp-down($bp-lg) {
                    text-align: center;
                }

                &.logo-col {
                    align-items: center;

                    .logo { padding: .25rem .5rem; }
                }

                @include bp-up($bp-xl + 1) {
                    &.programs-col .links { display: flex; }
                }

                .heading {
                    @include fluid-size(18px, 22px, font-size);
                    font-weight: 100;
                    padding: .25rem 1rem;

                    @include bp-up($bp-lg + 1) {
                        margin-bottom: 1rem;
                    }

                    @include bp-down($bp-lg) {
                        margin-top: 2rem;
                    }
                }

                :deep(a) {
                    display: block;
                    padding: .25rem 1rem;
                    line-height: 1.1
                }
            }
        }

        .copyright {
            @include fluid-size(32px, 48px, padding-right);
            @include fluid-size(32px, 48px, padding-left);
            @include fluid-size(32px, 48px, padding-bottom);

            @include bp-down($bp-lg) { text-align: center; }
        }
    }
</style>