<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('Insured_name')" @click="sortBy('Insured_name')" v-if="userIn(['BR','AD'])">Insured</p>
            <p :class="sortClass('Claim_Number')" @click="sortBy('Claim_Number')">Claim No.</p>
            <p :class="sortClass('cld')" @click="sortBy({'cld': ['Claimant','Loss_Short_Desc']})">Claim&shy;ant/<wbr>Desc.</p>
            <p :class="sortClass('Loss_Date')" @click="sortBy('Loss_Date','date')">Loss Date</p>
            <p :class="sortClass('Claim_Status')" @click="sortBy('Claim_Status')">Claim Status</p>
            <p :class="sortClass('Tot_Incurred')" @click="sortBy('Tot_Incurred','number')">Total Incurred</p>
            <p :class="sortClass('Policy_number')" @click="sortBy('Policy_number')" v-if="userIn(['BR','EM','AD','CN'])">Policy No.</p>
        </div>
        <div class="simple-table" :style="`--cols:${['AD','BR'].some(t=>userType==t)?7:6}`">
            <p class="st-header" :class="[sortClass('Insured_name'),userIn(['BR','AD'])?'first-col':'']" @click="sortBy('Insured_name')" v-if="userIn(['BR','AD'])">Insured</p>
            <p class="st-header" :class="[sortClass('Claim_Number'),userIn(['CN'])?'first-col':'']" @click="sortBy('Claim_Number')">Claim No.</p>
            <p class="st-header" :class="sortClass('cld')" @click="sortBy({'cld': ['Claimant','Loss_Short_Desc']})">Claim&shy;ant/<wbr>Desc.</p>
            <p class="st-header" :class="sortClass('Loss_Date')" @click="sortBy('Loss_Date','date')">Loss Date</p>
            <p class="st-header" :class="sortClass('Claim_Status')" @click="sortBy('Claim_Status')">Claim Status</p>
            <p class="st-header" :class="[sortClass('Tot_Incurred'),userNotIn(['CN'])?'last-col':'']" @click="sortBy('Tot_Incurred','number')">Total Incurred</p>
            <p class="st-header" :class="sortClass('Policy_number')" @click="sortBy('Policy_number')" v-if="userIn(['BR','EM','AD','CN'])">Policy No.</p>

            <template v-for="(row,index) in displayedList" :key="'pending_invite_' + row.id">
                <p :class="userIn(['BR','AD'])?'first-col':''" v-if="userIn(['BR','AD'])"><span class="st-hidden">Insured</span>{{ row.Insured_name }}</p>
               <p :class="userIn(['CN','EM'])?'first-col':''" @click="setActiveClaim(row.Claim_ID, index)"><span class="st-hidden">Claim No.</span><span :class="activeClaimLoading?'':'cursor-pointer underline'">{{ row.Claim_Number }}</span></p>
                <div>
                    <span class="st-hidden">Claim&shy;ant/<wbr>Desc.</span>
                    <div class="text-right xl:text-left">
                        <p><strong>{{ row.Claimant }}</strong></p>
                        <p>{{ row.Loss_Short_Desc }}</p>
                    </div>
                </div>
                <p><span class="st-hidden">Loss Date</span>{{ formatDate(row.Loss_Date) }}</p>
                <p><span class="st-hidden">Claim Status</span><Status :status="row.Claim_Status" /></p>
                <p><span class="st-hidden">Total Incurred</span>{{ formatMoney(row.Tot_Incurred) }}</p>
                <p v-if="userIn(['AD','BR','EM','CN'])" class="last-col"><span class="st-hidden">Policy No.</span><Link class="underline" :href="route('policies.details', row.Contract_ID)">{{ row.Policy_number }}</Link></p>
            </template>
            <div class="bg-gray-100 dropdown-panel" :class="[activeClaim?'active':'',activeClaimLoading?'loading':'']" :style="getGridArea">
                <ClaimsTableDetailsDropdown :data="activeClaimDetails" />
                <div class="loader" v-if="activeClaimLoading"></div>
            </div>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import ClaimsTableDetailsDropdown from '../Partials/ClaimsTableDetailsDropdown.vue'
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'

    export default {
        mixins: [tableMixin,formattingMixin],
        props: ["userType"],
        components: {
            ClaimsTableDetailsDropdown,
            SimpleTableWrapper,
            Status,
            Link,
        },
        data() {
            return {
                activeClaim: null,
                activeClaimDetails: null,
                activeClaimLoading: false,
                activeClaimIndex: null,
            }
        },
        watch: {
            activeClaim(n) {
                if (n) {
                    this.activeClaimLoading = true;
                    this.activeClaimDetails = null;

                    axios.get(`/claim-list-details`, { params: { claimId: n } })
                        .then(res => {
                            this.activeClaimLoading = false,
                            this.activeClaimDetails = res.data
                        })
                        .catch(err => {
                            console.error(err);
                        })
                } else {
                    this.activeClaimDetails = null;
                }
            },
            displayedList: {
                handler() {
                    this.activeClaim = null;
                    this.activeClaimDetails = null;
                },
                deep: true
            }
        },
        computed: {
            getGridArea() {
                return `--grid-position:${this.activeClaimIndex+3};--grid-mobile-position: ${(this.activeClaimIndex*7)+4}`;
            },
        },
        methods: {
            userIn(typeArr) {
                return typeArr.some(type => this.userType==type);
            },
            userNotIn(typeArr) {
                return typeArr.every(type => this.userType!=type);
            },
            setActiveClaim(claimId, index) {
                if (!this.activeClaimLoading) {
                    this.activeClaim = this.activeClaim !== claimId ? claimId : null;
                    this.activeClaimIndex = index;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), 1fr);
    }

    .dropdown-panel {
        position: relative;
        overflow: hidden;
        transition: 200ms ease-out;
        display: block !important;
        grid-column: 1 / -1;

        @include bp-up($bp-xl + 1) { grid-row: var(--grid-position) / span 1; }
        @include bp-down($bp-xl) { grid-row: var(--grid-mobile-position) / span 1; }

        &.loading {
            padding-top: 50px !important;
            padding-bottom: 50px !important;
        }

        &:not(.active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            opacity: 0;
        }

        &.active {
            height: auto;
            padding: 1rem;
            box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
    }
</style>
