<template>
    <Card color="blue-500" title="Claim Servicing">
         <div class="px-4">
            <p class="phone-grid">
                <span class="a1 font-bold">Office:</span>
                <span class="a2 ml-4"><a :href="'tel:'+pdata.claimSupportInfo.claim_servicing_phone">{{pdata.claimSupportInfo.claim_servicing_phone}}</a></span>
                <span class="b1 font-bold">Fax:</span>
                <span class="b2 ml-4">{{pdata.claimSupportInfo.claim_servicing_fax}}</span>
            </p>
            <a :href="'mailto:'+pdata.claimSupportInfo.claim_servicing_email" class="underline">{{pdata.claimSupportInfo.claim_servicing_email}}</a>
        </div>
    </Card>
</template>

<script>
import Card from '@/Components/Card.vue'

export default {
    props: ["pdata"],
    components: {
        Card,
    },
}
</script>

<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';

    .phone-grid {
        display: grid;
        grid-template-columns: min-content auto;
        grid-auto-flow: row dense;
        grid-auto-rows: auto;
    }
</style>