<template>
    <AppLayout title="Bond Details">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'Policies',route:'policies.policies'},`Bond Number: ${pdata.policy_number}`]"/>
        </template>

        <div class="lg:flex -m-4">
            <div class="lg:w-2/3 2xl:w-3/4">
                <div class="p-4">
                    <BondDetailsSection :pdata="pdata"/>
                </div>
            </div>
            <div class="lg:w-1/3 2xl:w-1/4">
                <div class="p-4">
                    <YourAccountManagerSectionBonds :pdata="pdata" :user="$page.props.user" />
                </div>
            </div>
        </div>
   </AppLayout>
</template>

<script>
    import PolicyDetailsCertificatesTable from './Tables/PolicyDetailsCertificatesTable.vue'
    import YourAccountManagerSectionBonds from './Sections/YourAccountManagerSectionBonds.vue'
    import BondDetailsSection from './Sections/BondDetailsSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            PolicyDetailsCertificatesTable,
            YourAccountManagerSectionBonds,
            BondDetailsSection,
            Breadcrumbs,
            JetCheckbox,
            AppLayout,
            JetLabel,
            JetInput,
            Link,
            Card,
        },
        props: {
            pdata: Object,
        },
        methods: {
            openModal() {
                this.showActMgrModal = true;
            },
            closeModal() {
                this.isOpen = false;
                this.reset();
                this.showActMgrModal = false;
            },
            reset() {
                this.form = {
                    your_name: null,
                    email: null,
                    mobile: null,
                    message: null,
                    account_manager_email:null,
                    account_manager_name:null
                }
            }
        }
    }
</script>
