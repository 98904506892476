<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('Contract_Number')" @click="sortBy('Contract_Number')">Policy No.</p>
            <p></p>
            <p :class="sortClass('Posted_Balance')" @click="sortBy('Posted_Balance')">Invoice Amount</p>
            <p :class="sortClass('Due_Date')" @click="sortBy('Due_Date')">Due Date</p>
        </div>
        <div class="simple-table" style="--cols:4">
            <p class="st-header first-col" :class="sortClass('Contract_Number')" @click="sortBy('Contract_Number')">Policy No.</p>
            <p class="st-header">Remove</p>
            <p class="st-header" :class="sortClass('Posted_Balance')" @click="sortBy('Posted_Balance')">Invoice Amount</p>
            <p class="st-header last-col" :class="sortClass('Due_Date')" @click="sortBy('Due_Date')">Due Date</p>

            <template v-for="(row,index) in displayedList" >
                <p class="first-col"><span class="st-hidden">Policy No.</span>{{ row.Contract_Number }}</p>
                <p class="hoverMe text-red-600" @click="remove(row,index)"><span class="st-hidden"></span><FontAwesomeIcon class="ml-4" :icon="xMark"/></p>
                <p><span class="st-hidden">Invoice Amount</span>{{ formatMoney(row.Posted_Balance) }}</p>
                <p class="last-col"><span class="st-hidden">Due Date</span>{{ formatDate(row.Due_Date) }}</p>
            </template>
        </div>
    </SimpleTableWrapper>
    <div>
        <span>Subtotal: {{ formatMoney(invoicesTotal) }}</span>
        <em>(Does not reflect any transation fees)</em>
    </div>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'
    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            FontAwesomeIcon,
            Status,
            Link,
        },
        emits: ['update-invoices', 'update-total'],
        data() {
            return {
                xMark: faMinusCircle,
                activePolicy: null,
                activePolicyDetails: null,
                activePolicyLoading: false,
                activePolicyIndex: null,
                claimServiceModalVisible: false,
                entity_ids: this.$page.props.user.login_entity_ids,
                invoices: [],
            }
        },
        watch: {
            displayedList: {
                handler() {
                    this.activePolicy = null;
                    this.activePolicyDetails = null;
                },
                deep: true
            }
        },
        computed: {
            getGridArea() {
                return `--grid-position:${this.activePolicyIndex+3};--grid-mobile-position: ${(this.activePolicyIndex*7)+4}`;
            },
            invoicesTotal() {
                var total = 0.00;
                for (const listItem of this.displayedList) {
                    total = total + listItem.Posted_Balance;
                }
                return total;
            }
        },
        methods: {
            remove(row, index){
                this.data.splice(index, 1);
                this.invoices.splice(index, 1);
                this.$emit('update-invoices', this.invoices);
            },
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), 1fr);
    }
    .hoverMe { cursor: pointer; }
    .dropdown-panel {
        position: relative;
        overflow: hidden;
        transition: 200ms ease-out;
        display: block !important;
        grid-column: 1 / -1;

        @include bp-up($bp-xl + 1) { grid-row: var(--grid-position) / span 1; }
        @include bp-down($bp-xl) { grid-row: var(--grid-mobile-position) / span 1; }

        &.loading {
            padding-top: 50px !important;
            padding-bottom: 50px !important;
        }

        &:not(.active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            opacity: 0;
        }

        &.active {
            height: auto;
            padding: 1rem;
            box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
    }
    .expand-details {
        cursor: pointer;
    }
</style>
