<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('company_name')" @click="sortBy('company_name')" v-if="$page.props.user.role_type!='CN'">Company Name</p>
            <p :class="sortClass('entity_id')" @click="sortBy('entity_id', 'number')">Entity ID</p>
        </div>
        <div class="simple-table" style="--cols:2">
            <p class="st-header first-col" :class="sortClass('company_name')" @click="sortBy('company_name')" v-if="$page.props.user.role_type!='CN'">Company Name</p>
            <p class="st-header last-col" :class="sortClass('entity_id')" @click="sortBy('entity_id', 'number')">Entity ID</p>
            <template v-for="row in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col">
                    <span class="st-hidden">Company Name</span>
                    <a href="" class="underline" @click.prevent="selectEntity(row.entity_id)">{{ row.company_name }}</a></p>
                <p class="last-col">
                    <span class="st-hidden">Entity ID</span>
                    <a href="" class="underline" @click.prevent="selectEntity(row.entity_id)">{{ row.entity_id }}</a>
                </p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'

    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            Status,
            Link,
        },
        emits: ['selected-entity'],
        methods: {
            selectEntity(entity) {
                this.$emit('selected-entity', entity);
            }
        },
    }
</script>
