<template>
    <Head title="Log in" />

    <SplashScreen :animate="true" />

    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />

        <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
            {{ status }}
        </div>

        <form @submit.prevent="submit">
            <div>
                <JetLabel for="email" value="Email" />
                <JetInput id="email" type="email" class="mt-1 block w-full" v-model="form.email" required autofocus />
            </div>

            <div class="mt-4">
                <SimplePassword v-model="form.password" autocomplete="current-password" />
            </div>

            <div class="block mt-4">
                <label class="flex items-center">
                    <JetCheckbox name="remember" v-model:checked="form.remember" />
                    <span class="ml-2 text-sm text-gray-600">Remember me</span>
                </label>
            </div>

            <div class="flex items-center justify-end mt-4">
                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Log in
                </JetButton>
            </div>

            <div class="flex flex-wrap items-center justify-between mt-3 -mx-1 -mb-1">
                <Link :href="route('portal.request')" class="underline text-sm text-gray-600 hover:text-gray-900">
                    Not registered?  Click Here <br>
                </Link>
                 <Link :href="route('password.request')" class="underline text-sm text-gray-600 hover:text-gray-900">
                    Forgot your password?
                </Link>
            </div>

        </form>
    </JetAuthenticationCard>
</template>

<script>
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SimplePassword from '@/Components/FormElements/SimplePassword.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import SplashScreen from '@/Components/SplashScreen.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetAuthenticationCardLogo,
            JetAuthenticationCard,
            JetValidationErrors,
            SimplePassword,
            SplashScreen,
            JetCheckbox,
            JetButton,
            JetInput,
            JetLabel,
            Head,
            Link,
        },

        props: {
            canResetPassword: Boolean,
            status: String
        },

        data() {
            return {
                form: this.$inertia.form({
                    email: '',
                    password: '',
                    remember: false
                })
            }
        },

        methods: {
            submit() {
                this.form
                    .transform(data => ({
                        ... data,
                        remember: this.form.remember ? 'on' : ''
                    }))
                    .post(this.route('login'), {
                        onFinish: () => this.form.reset('password'),
                    })
            }
        }
    }
</script>
