require('./bootstrap');

import { createApp, h, ref } from 'vue';
import { createInertiaApp,Link,useRemember } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import Datepicker from 'vue3-datepicker'
const picked = ref(new Date())
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    setup({ el, app, props, plugin }) {
        return createApp({ render: () => h(app, props) })
            .use(plugin)
            .mixin({ methods: { route } })
            .mount(el);
    },
});
//createInertiaApp.remember("Test User", 'login_id')
InertiaProgress.init({ color: '#4B5563' });
