<template>
    <input class="border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input">
</template>

<script>
    export default {
        props: ['modelValue'],

        emits: ['update:modelValue'],

        methods: {
            focus() {
                this.$refs.input.focus()
            }
        }
    }
</script>

