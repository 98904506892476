<template>
    <AppLayout title="Claims">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'My View',route:'policies.policies'},'Claims']"/>
        </template>

        <ClaimsSection :entity-id="entityId" />
        <div class="mt-4 flex flex-wrap justify-end -m-4">
            <div class="p-4">
                <ClaimServicingSection :entity-id="entityId" />
            </div>

            <div class="p-4" v-if="userType=='CN'">
                <SubmitClaimSection :entity-id="entityId" /> <!-- the form the section pops up is dependant on pdata for things like insured_name -->
            </div>
        </div>
    </AppLayout>
</template>

<script>
    import ClaimServicingSection from './Details/Sections/ClaimServicingSection.vue'
    import SubmitClaimSection from './Details/Sections/SubmitClaimSection.vue'
    import ClaimsSection from './Sections/ClaimsSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        props:["pdata"],
        components: {
            ClaimServicingSection,
            SubmitClaimSection,
            ClaimsSection,
            Breadcrumbs,
            AppLayout,
        },
        computed: {
            entityId() {
                //GET ID FROM PATH
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var segment2 = parseInt(segment_array.pop());
                var segment1 = segment_array.pop();
                var entityId = segment2;
                return entityId;
            },
            userType() {
                return this.$page.props.user.role_type;
            },
        }
    }
</script>
