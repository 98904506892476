<template>
    <Card color="blue-500" title="Your Account Manager" :cta-route="ctaButtonLink">
        <div class="flex flex-wrap -m-2">
            <div class="p-2" style="flex:1 0 120px">
                <img
                    class="w-full"
                    v-bind:src="pdata.account_manager_img"
                    alt="Image of NAME OF ACCOUNT MANAGER"
                /> <!-- needs to be hooked up -->
            </div>
            <div class="p-2 text-gray-500" style="flex:9999 0 auto">
                <h6 class="leading-tight mb-1 font-bold">{{ pdata.account_manager_name }}</h6> <!-- needs to be hooked up -->
                <div class="text-sm">
                    <p class="phone-grid">
                        <span class="font-bold">Office:</span>
                        <span class="ml-4"><a :href="'tel:'+pdata.account_manager_phone.replace(/\D/g,'')">{{ pdata.account_manager_phone }}</a></span>
                        <span class="font-bold">Fax:</span>
                        <span class="ml-4">{{ pdata.account_manager_fax }}</span>
                    </p>
                    <p @click.prevent="contactAccountManagerModalVisible = true" class="cursor-pointer underline"> {{ pdata.account_manager_email }}</p>
                </div>
            </div>
        </div>


        <template #ctaText>
            <p>Log In to Bondlink</p>
        </template>

        <SimpleModal :visible="contactAccountManagerModalVisible" @on-close="contactAccountManagerModalClose()" width="800px">
            <ContactAccountManagerModalContent :visible="contactAccountManagerModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="contactAccountManagerModalClose()" :pdata="pdata" />
        </SimpleModal>

        <SimpleModal :visible="additionalCertificateHolderModalVisible" @on-close="additionalCertificateHolderModalClose()" width="800px">
            <AdditionalCertificateHolderModalContent :visible="additionalCertificateHolderModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="additionalCertificateHolderModalClose()" :pdata="pdata" />
        </SimpleModal>

        <SimpleModal :visible="additionalInsuredModalVisible" @on-close="additionalInsuredModalClose()" width="800px">
            <AdditionalInsuredModalContent :visible="additionalInsuredModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="additionalInsuredModalClose()" :pdata="pdata" />
        </SimpleModal>

        <SimpleModal :visible="lossRunModalVisible" @on-close="lossRunModalClose()" width="800px">
            <LossRunModalContent :visible="lossRunModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="lossRunModalClose()" :pdata="pdata" />
        </SimpleModal>

        <SimpleModal :visible="lossPayeeModalVisible" @on-close="lossPayeeModalClose()" width="800px">
            <LossPayeeModalContent :visible="lossPayeeModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="lossPayeeModalClose()" :pdata="pdata" />
        </SimpleModal>

        <SimpleModal :visible="otherPolicyRequestModalVisible" @on-close="otherPolicyRequestModalClose()" width="800px">
            <OtherPolicyRequestModalContent :visible="otherPolicyRequestModalVisible" :reset-form-trigger="resetFormTrigger" @on-close="otherPolicyRequestModalClose()" :pdata="pdata" />
        </SimpleModal>
    </Card>
</template>

<script>
import AdditionalCertificateHolderModalContent from './AdditionalCertificateHolderModalContent.vue'
import ContactAccountManagerModalContent from './ContactAccountManagerModalContent.vue'
import OtherPolicyRequestModalContent from './OtherPolicyRequestModalContent.vue'
import AdditionalInsuredModalContent from './AdditionalInsuredModalContent.vue'
import LossPayeeModalContent from './LossPayeeModalContent.vue'
import LossRunModalContent from './LossRunModalContent.vue'
import SimpleModal from '@/Components/SimpleModal.vue'
import Card from '@/Components/Card.vue'

export default {
    props: ["pdata", "user"],
    components: {
        AdditionalCertificateHolderModalContent,
        ContactAccountManagerModalContent,
        OtherPolicyRequestModalContent,
        AdditionalInsuredModalContent,
        LossPayeeModalContent,
        LossRunModalContent,
        SimpleModal,
        Card,
    },
    data() {
        return {
            additionalCertificateHolderModalVisible: false,
            contactAccountManagerModalVisible: false,
            otherPolicyRequestModalVisible: false,
            additionalInsuredModalVisible: false,
            lossPayeeModalVisible: false,
            lossRunModalVisible: false,
            resetFormTrigger: false
        }
    },
    computed: {
        ctaButtonLink() {
            return 'https://vgminsurance.com/vgmbonds/log-in';
        }
    },
    methods: {
        contactAccountManagerModalClose() {
            this.contactAccountManagerModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        additionalCertificateHolderModalClose() {
            this.additionalCertificateHolderModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        additionalInsuredModalClose() {
            this.additionalInsuredModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        lossRunModalClose() {
            this.lossRunModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        lossPayeeModalClose() {
            this.lossPayeeModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
        otherPolicyRequestModalClose() {
            this.otherPolicyRequestModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';

    .phone-grid {
        display: grid;
        grid-template-columns: min-content auto;
        grid-auto-flow: row dense;
        grid-auto-rows: auto;
    }
</style>
