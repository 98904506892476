<template>
    <Card color="teal" title="Bond Details">
        <div class="top-buttons my-2 xl:w-1/2 w-auto bg-indigo-500 text-white p-2 hover:underline">
            <a href="https://vgminsurance.com/vgmbonds/log-in" target="_blank">
                Please note that all Surety Bond servicing requests must be sent through Bondlink, our Surety Bond servicing portal. Log In to Bondlink to apply for a bond, renew a bond, submit bond changes, update contact information, & issue payment.
            </a>
        </div>
        <h5>Insured</h5>
        <div class="xl:flex mt-4 align-stretch -mx-4">
            <div class="p-4 xl:w-1/2 2xl:w-1/3">
                <div class="bg-gray-200 text-gray-500 p-4 leading-tight">
                    <div class="-my-2">
                        <p class="my-2 font-bold">{{ pdata.insured_name }}</p>
                        <p class="my-2 font-bold">{{ pdata.insured_address }}</p>
                        <p class="my-2 font-bold">{{ pdata.insured_city }}, {{ pdata.insured_state }} {{ pdata.insured_zip }}</p>
                        <p class="my-2 font-bold break-words">{{ pdata.insured_email }}</p>
                    </div>
                </div>
            </div>
            <div class="p-4 xl:w-1/2 2xl:w-2/3 text-sm text-gray-500">
                <p class="details-grid">
                    <span class="a1 font-bold">Bond Number:</span>
                    <span class="a2 break-all">{{ pdata.policy_number }}</span>

                    <span class="b1 font-bold">Status:</span>
                    <span class="b2"><Status :status="pdata.status" /></span>

                    <span class="c1  font-bold">Effective Date:</span>
                    <span class="c2 ">{{ formatDate(pdata.Effective_Date) }}</span>

                    <span class="d1 font-bold">Expiration Date:</span>
                    <span class="d2">{{ formatDate(pdata.Expiration_Date) }}</span>

                    <span class="e1 font-bold">Line Of Business:</span>
                    <span class="e2">{{ pdata.line_of_business }}</span>

                    <span class="f1 font-bold">Program:</span>
                    <span class="f2">{{ pdata.Program }}</span>

                    <span class="g1 font-bold">Written Premium:</span>
                    <span class="g2">{{ formatMoney(pdata.Written_Premium) }}</span>

                    <span class="h1 font-bold">Writing Company: </span>
                    <span class="h2">{{ removeLeadingNumbers(pdata.Writing_Company) }}</span>
                </p>
            </div>
        </div>

        <template v-if="pdata.coverageInfo.length">
            <h5>Coverages</h5>
            <PolicyDetailsCoverageTable
                v-if="coverageData.length"
                :data="coverageData"
                :cols="3"
                :items-per-page="4"
            />
        </template>
    </Card>
</template>

<script>
import PolicyDetailsCoverageTable from '../Tables/PolicyDetailsCoverageTable.vue'
import formattingMixin from '@/Mixins/formattingMixin.js'
import Status from '@/Components/Status.vue'
import Card from '@/Components/Card.vue'
import { Link } from '@inertiajs/inertia-vue3'

export default {
    mixins: [formattingMixin],
    props: ["pdata"],
    components: {
        PolicyDetailsCoverageTable,
        Status,
        Card,
        Link,
    },
    computed: {
        coverageData() {
            return this.pdata.coverageInfo.filter(v => v !== null);
        }
    },
    methods: {
        removeLeadingNumbers(string) {
            return string.replace(/^\d+ /, '');
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';

    $wide-template:
        "a1 a2 .  b1 b2" max-content
        "c1 c2 .  d1 d2" max-content
        ".  .  .  .  . " 1rem
        "e1 e2 .  g1 g2 " max-content
        "f1 f2 .  h1 h2 " max-content
        / max-content minmax(100px, max-content) minmax(1rem, 5rem) max-content minmax(100px, max-content);

    $narrow-template:
        "a1 a2" max-content
        "b1 b2" max-content
        "c1 c2" max-content
        "d1 d2" max-content
        ".  . " 1rem
        "e1 e2" max-content
        "f1 f2" max-content
        "g1 g2" max-content
        "h1 h2" max-content
        / max-content 1fr;

    .details-grid {
        @include fluid-size(-2px, -4px, margin-top);
        @include fluid-size(-2px, -4px, margin-bottom);
        @include fluid-size(-8px, -16px, margin-left);
        @include fluid-size(-8px, -16px, margin-right);
        display: grid;

        @include bp-up($bp-2xl + 1) { grid-template: $wide-template; }
        @include bp-between($bp-lg + 1, $bp-2xl) { grid-template: $narrow-template; }
        @include bp-between(551px, $bp-lg) { grid-template: $wide-template; }
        @include bp-down(550px) { grid-template: $narrow-template; }

        @include generate-grid-classes((a,b,c,d,e,f,g,h));

        > span {
            @include fluid-size(2px, 4px, padding-top);
            @include fluid-size(2px, 4px, padding-bottom);
            @include fluid-size(8px, 16px, padding-left);
            @include fluid-size(8px, 16px, padding-right);
        }
    }
    .top-buttons {
        position: relative;
        @include bp-up ($bp-2xl + 20) {
            position: absolute;
            top: 1rem;
            right: 1rem;
        }
    }
</style>
