<template>
    <Card color="teal" :title="insuredName + ' Policies'">
        <p class="-mt-4 mb-4">View the policies you have in place with VGM Insurance. Select an individual policy to view further details.</p>

        <div class="loader" v-if="loading"></div>

        <form @submit.prevent="formSubmit" enctype="multipart/form-data" v-show="$page.props.user.role_type == 'AD' || $page.props.user.role_type == 'EM'">
            <div class="flex align-stretch">
                <JetInput type="text" v-model="form.entity_name" placeholder="Enter Entity ID..." required  maxlength="35"/>
                <button  class="btn-blue flex-grow-0 rounded-l-none">Submit</button>
            </div>
        </form>

        <PoliciesTable
            v-if="search_data.length"
            :cols="$page.props.user.role_type!='CN'?7:6"
            respond-at="lg"
            class="mt-6"
            :data="search_data"
            :searchable-data="['Contract_Num']"
            search-placeholder="Search by Policy No..."
        />
        <p v-if="!search_data.length && form.entity_name && !loading" class="mt-4 bg-gray-200 p-4">No policies found</p>
    </Card>
</template>

<script>
import PoliciesTable from '../Tables/PoliciesTable.vue'
import JetInput from '@/Jetstream/Input.vue'
import Card from '@/Components/Card.vue'

export default {
    components: {
        PoliciesTable,
        JetInput,
        Card,
    },
    props: {
        entityId: [Number, String]
    },
    data() {
        return {
            form: {
                entity_name: null,
                entity_id: null,
            },
            loading: false,
            search_data: [],
        };
    },
    computed: {
        insuredName() {
            var firstSearch = this.search_data[0];
            if(firstSearch) {
                return firstSearch.insured_name;
            } else {
                return 'My';
            }
        },
        policySearch() {
                //GET ID FROM PATH
                var segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
                var segment_array = segment_str.split( '/' );
                var segment2 = parseInt(segment_array.pop());
                var segment1 = segment_array.pop();
                var entityId = segment2;
                return Number.isInteger(entityId);
            }
    },
    mounted() {
        if (this.entityId) {
            this.form.entity_name = this.entityId;
            this.form.entity_id = this.entityId;
            this.formSubmit();
        }
    },
    methods: {
        formSubmit() {
            this.loading = true;
            if(this.policySearch){
                axios.post('/policies/search', this.form)
                    .then( response =>  {
                        this.loading = false,
                        this.search_data = [];
                        // Loop through array result
                        response.data.search_results.forEach(result => {
                            // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                            this.search_data.push(Object.freeze(result));
                        });
                })
            } else {
                 axios.post('/policies/company_search', this.form)
                .then( response =>  {
                    this.loading = false,
                    this.search_data = [];
                    // Loop through array result
                    response.data.search_results.forEach(result => {
                        // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                        this.search_data.push(Object.freeze(result));
                    });
                })
            }
        }
    }
}
</script>

<style>

</style>
