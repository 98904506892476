<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('entity_name')" @click="sortBy('entity_name')">Name</p>
            <p :class="sortClass('address')" @click="sortBy('address')">City/State</p>
        </div>
        <div class="simple-table" style="--cols:3">
            <p class="st-header first-col" :class="sortClass('entity_name')" @click="sortBy('entity_name')">Name</p>
            <p class="st-header" :class="sortClass('address')" @click="sortBy('address')">City/State</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row) in displayedList" :key="'manage_customers_' + row.entity_id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.entity_name }}</p>
                <p><span class="st-hidden">City/State</span>{{ row.address }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <Link :href="`/customers/view-claims/${row.entity_id}`" class="btn-blue" as="button" type="button">View Claims</Link>
                        <Link :href="`/customers/view-policies/${row.entity_id}`" class="btn-teal" as="button" type="button">View Policies</Link>
                    </div>
                </div>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'

    export default {
        mixins: [tableMixin],
        components: {
            SimpleTableWrapper,
            Link,
        },
        methods: {
            edit(data) {
                this.$emit('edit', data);
            },
            deleteRow(data) {
                this.$emit('delete-row', data);
            },
        }
    }
</script>
<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
