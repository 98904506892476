<template>
    <app-layout title="Import Users">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'Manage Users',route:'users.index'},'Import Users']"/>
        </template>

        <Card color="gray-500" title="Import Users">
            <div class="loader" v-if="loading"></div>
            <!--<label class="form-control-label" for="input-file-import">Upload Excel File</label>  -->

            <!--success/error message-->
            <p
                v-if="success"
                class="mt-4 mb-3 font-medium text-lg text-green-600"
            >{{ success }}</p>
            <p
                v-if="error"
                class="mt-4 mb-3 font-medium text-lg text-red-600"
            >{{ error }}</p>


            <div class="top-bar">
                <FileUploader
                    accept=".xlsx"
                    post="/users/import"
                    @on-then="onThen($event)"
                    @on-catch="onCatch($event)"
                    @on-finally="onFinally($event)"
                />

                <div class="buttons">
                    <button
                        class="btn-gray"
                        @click="download"
                    >Download Demo File</button>

                    <button
                        v-if="pending_invitations.length"
                        @click="sendInvitation"
                        class="btn-blue"
                    >Import &amp; Invite</button>
                </div>
            </div>

            <ImportUsersTable
                class="mt-6"
                :cols="4"
                v-if="pending_invitations.length"
                :data="pending_invitations"
                :searchable-data="['first_name', 'last_name', 'role_type', 'email', 'status_msg']"
            />
        </Card>
   </app-layout>
</template>



<script>
    import ImportUsersTable from './Tables/ImportUsersTable.vue'
    import FileUploader from '@/Components/FileUploader.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import { Link } from '@inertiajs/inertia-vue3'
    import Welcome from '@/Jetstream/Welcome.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            ImportUsersTable,
            FileUploader,
            Breadcrumbs,
            AppLayout,
            Welcome,
            Card,
            Link,
        },
        props: ['data', 'errors'],
        data() {
            return {
                pending_invitations: [],
                elementVisible: true,
                isInvActive: false,
                loading: false,
                success: '',
                error: '',
                name: '',
            };
        },
        methods: {
            onThen(res) {
                this.fetchUserInvitations();
                this.success = res.message;
            },
            onCatch(err) {
                console.log("Catch Block", err.response);
            },
            onFinally() {
                this.loading =  false;
            },
            fetchUserInvitations() {
                this.isInvActive = true,
                axios.get(route('session_invitations'))
                    .then(response => {
                        this.pending_invitations = response.data
                    })
            },
            download() {
                const url = '/uploads/import_users_demo.xlsx';
                window.location.href = url;
            },
            sendInvitation() {
                let vm = this;
                this.loading =  true,
                axios.get(route('send_invitation'))
                    .then(response => {
                        this.isInvActive = false,
                        vm.success = response.data.message;
                        this.loading = false
                    }).catch(
                        function (error) {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                vm.error = error.response.data.error;
                                vm.isInvActive = false,
                                vm.loading = false
                            } 
                    });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .top-bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: -.5rem;

        :deep(button) { margin: .5rem; }
    }
</style>