<template>

</template>

<script>
import Card from '@/Components/Card.vue'

export default {
    props: ["pdata"],
    components: {
        Card,
    },
}
</script>

<style lang="scss" scoped>
    @import '../../../../../../scss/_mixins.scss';

    .phone-grid {
        display: grid;
        grid-template-columns: min-content auto;
        grid-auto-flow: row dense;
        grid-auto-rows: auto;
    }
</style>
