<template>
    <span class="flex items-center"><FontAwesomeIcon :icon="circle" class="mr-2" :class="color" style="font-size:12px;"/>{{ status }}</span>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'

export default {
    components: {
        FontAwesomeIcon
    },
    props: ['status'],
    data() {
        return {
            circle: faDotCircle
        }
    },
    computed: {
        color() {
            // if positive status
            if (this.checkGroup(['InForce', 'Active', 'Open', ' Processed'])) return 'text-green-500';
            // if warning status
            else if (this.checkGroup(['Incident', 'Expired', ' Pending'])) return 'text-yellow';
            // if negative status
            else if (this.checkGroup(['Dead', 'Inactive', ' Open'])) return 'text-red';
            // if neutral/no status
            else return 'text-gray-400';
        }
    },
    methods: {
        checkGroup($arr) {
            return $arr.some(q=>this.status.toLowerCase()==q.toLowerCase());
        }
    }
}
</script>

<style>

</style>
