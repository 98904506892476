<template>
    <div class="file-upload-form">
        <form enctype="multipart/form-data">
            <input ref="fileInput" class="file-input" type="file" :accept="accept" @change="sendForm()">
        </form>
        <button class="btn-blue" @click.prevent="$refs.fileInput.click()">{{ buttonText }}</button>
    </div>
</template>

<script>
export default {
    name: "FileUploader",
    props: {
        accept: {
            type: String,
            default: ".xlsx"
        },
        post: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            buttonText: 'Upload',
        }
    },
    methods: {
        sendForm() {
            let vm = this,
                input = event.target,
                file = input.files[0],
                form = input.closest('form'),
                config = { headers: {'content-type': 'multipart/form-data'} },
                data = new FormData();
				data.append('file', file);

				this.buttonText = `Uploading ${file.name}...`;

            setTimeout(function() {
                axios.post(vm.post, data, config)
                    .then(function (res) {
                        vm.$emit('on-then', res);
                        vm.$page.props.jetstream.flash.banner = `Successfully uploaded ${file.name}`;
                    })
                    .catch(function (err) {
                        if (err) vm.$emit('on-catch', err);
                    }).finally(function() {
                        vm.$emit('on-finally');
                        vm.buttonText = `Upload`;
                        form.reset()
                    });
            }, 1000)

        },
    }
}
</script>

<style lang="scss" scoped>
    .file-input {
        display: none;
    }
</style>