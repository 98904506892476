<template>
    <AppLayout title="Programs">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Programs']"/>
        </template>

        <div class="program-grid md:grid -my-4 md:my-0">
            <Card color="blue-500" :ctaRoute="programs('home-medical-equipment')" title="Home Medical Equip&shy;ment" cta-cover>
                <p class="text-sm">Tailored insurance solutions designed for the unique needs of home medical equipment businesses.</p>
            </Card>

            <Card color="blue-500" :ctaRoute="programs('medical-manufacturer-reps-distributors')" title="Medical Manufac&shy;turer Reps &amp; Distri&shy;butors" cta-cover>
                <p class="text-sm">Full-line insurance solutions customized to the unique needs of medical distributors and manufacturer representatives.</p>
            </Card>

            <Card color="blue-500" :ctaRoute="programs('home-health-hospice')" title="Home Health &amp; Hospice" cta-cover>
                <p class="text-sm">Specialized insurance solutions designed for home healthcare and hospice providers.</p>
            </Card>

            <Card color="teal" :ctaRoute="programs('additional-allied-healthcare-insurance')" title="All Allied Health&shy;care" cta-cover>
                <p class="text-sm">Specialized insurance solutions for a variety of businesses in the Allied Healthcare industry.</p>
            </Card>

            <Card color="teal" :ctaRoute="programs('orthotic-prosthetic')" title="Orthotic &amp; Prosth&shy;etic" cta-cover>
                <p class="text-sm">Custom insurance solutions designed for the specialized neeeds of O&amp;P providers.</p>
            </Card>

            <Card color="teal" :ctaRoute="programs('physical-therapy')" title="Physical Therapy" cta-cover>
                <p class="text-sm">Insurance solutions designed for the rehab industry by rehab professionals.</p>
            </Card>

            <Card color="green-500" :ctaRoute="vins('vgmbonds')" title="Bonds" cta-cover>
                <p class="text-sm">Competitive surety bond solutions offered by our team of experts to keep your business compliant.</p>
            </Card>

            <Card color="green-500" :ctaRoute="programs('golf-insurance')" title="Golf Insurance" cta-cover>
                <p class="text-sm">Comprehensive coverage options for the clubhouse to the 18th hole, and everything in between.</p>
            </Card>
        </div>
    </AppLayout>
</template>

<script>
import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import Card from '@/Components/Card.vue'

export default {
    components: {
        Breadcrumbs,
        AppLayout,
        Card,
    },
    methods: {
        vins(url) {
            return `https://www.vgminsurance.com/${url}`;
        },
        programs(url) {
            return this.vins(`programs/${url}`);
        },
        resources(url) {
            return this.vins(`resources/${url}`);
        },
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .program-grid {
        grid-auto-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-auto-flow: column dense;
        grid-gap: 2rem;

        > * {
            margin: 2rem 0;

            @include desktop {
                margin: 0;
            }
        }
    }
</style>