<template>
    <div class="card" :class="{'cta-cover':ctaCover,'has-image':image}" :style="cardColor">
        <img v-if="image" :src="image" :alt="title + ' iamge'">

        <h4>{{ title }}</h4>

        <div class="card-body">
            <slot></slot>
        </div>

        <slot name="cta"></slot>

        <component :is="isRoute?'Link':'a'" class="cta-box" v-if="!$slots.cta && ctaRoute" :target="isRoute?'_blank':false" :href="ctaHref">
            <slot name="ctaText"></slot>
            <FontAwesomeIcon :class="{'ml-4': $slots.ctaText}" :icon="chevronRight"/>
        </component>

        <div v-if="ctaCover && !$slots.cta" class="cta-box">
            <slot name="ctaText"></slot>
            <FontAwesomeIcon :class="{'ml-4': $slots.ctaText}" :icon="chevronRight"/>
        </div>
    </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "Card",
    props: {
        color: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        ctaRoute: {
            type: String,
        },
        ctaCover: {
            type: Boolean,
            default: false
        },
        image: {
            type: String
        }
    },
    components: {
        FontAwesomeIcon,
        Link,
    },
    data() {
        return {
            chevronRight: faChevronRight,
            isRoute: false,
        }
    },
    computed: {
        ctaHref() {
            let href;
            try {
                href = this.route(this.ctaRoute);
                this.isRoute = true;
            } catch (err) {
                console.groupCollapsed(this.ctaRoute + ' is not a registered inertia route.');
                console.warn(err);
                console.log('Using route as href literal instead');
                console.groupEnd();
                href = this.ctaRoute;
            }
            return href;
        },
        cardColor() {
            return `--card-color: var(--${this.color})`;
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .card {
        @include fluid-size(16px, 32px, padding-top);
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        border-top: 10px solid var(--card-color);
        box-shadow: 6px 6px 6px rgba(0,0,0,.1);
        font-weight: 100;
        position: relative;

        h4 {
            color: var(--card-color);
            font-weight: 600;
            padding-right: 1rem;
        }

        .card-body {
            margin: 1rem 0;
            padding-right: 1rem;
            flex-grow: 1;
        }

        :deep(.cta-box) {
            @include fluid-size(16px, 18px, font-size);
            align-self: flex-end;
            background-color: var(--card-color);
            height: 48px;
            min-width: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            padding: 1rem;
            font-weight: 700;

            &:hover svg {
                animation: bounce 2s infinite both;
            }
        }

        :deep(h5) {
            font-weight: 400;
            color: var(--gray-800);
        }

        &.cta-cover {
            position: relative;
            cursor: pointer;
            transition: 200ms ease-out;
            top: 0;

            &:hover {
                top: -5px;

                &:before {
                    height: 5px;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                height: 0;
                transition: 200ms ease-out;
            }

            a.cta-box {
                background-color: transparent;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                text-indent: -9999px;

                svg { display: none; }
            }
        }

        &.has-image img {
            position: relative;
            @include fluid-size(-16px, -32px, top);
            left: -1rem;
            width: calc(100% + 1rem);
            max-width: calc(100% + 1rem);
            object-fit: cover;
            height: 250px;
        }
    }

    @keyframes bounce {
        0%, 20%, 60%, 80%, 100% {transform: translateX(0);}
        40% {transform: translateX(-4px);}
        50% {transform: translateX(8px);}
        70% {transform: translateX(4px);}
    }
</style>