<template>
    <nav class="sidebar">
        <div class="logo">
            <Link :href="route('dashboard')">
                <jet-application-logo />
            </Link>
        </div>

        <div class="navigation">
            <div class="navigation-link" :class="{active: route().current('dashboard')}">
                <Link class="text-gray-700" :href="route('dashboard')">
                    <FontAwesomeIcon fixed-width :icon="houseUser"/><span>Dashboard</span>
                </Link>
            </div>

            <div class="navigation-link" :class="{active: route().current('policies.*')}" v-if="['EM', 'AD', 'CN', 'BR'].some(r => $page.props.user.role_type == r) || $page.props.user.isImpersonating">
                <!--<Link class="text-gray-700" :href="route('policies')">-->
                <Link class="text-gray-700" :href="route('policies.policies')">
                    <FontAwesomeIcon fixed-width :icon="penAlt"/><span>View My</span>
                </Link>
            </div>

            <div class="navigation-child-group" v-show="route().current('policies.*')">
                <div class="navigation-child" :class="{active: route().current('policies.policies')}">
                    <Link class="text-gray-700" :href="route('policies.policies')">
                        <span>Policies</span>
                    </Link>
                </div>

                <div v-if="$page.props.user.account_clearance == true || ['AD'].some(r => $page.props.user.role_type == r)" class="navigation-child" :class="{active: route().current('policies.accountclearance')}">
                    <Link class="text-gray-700" :href="route('policies.accountclearance')">
                        <span>Account Clearance</span>
                    </Link>
                </div>


                <div class="navigation-child" :class="{active: route().current('policies.claims')}">
                    <Link class="text-gray-700" :href="route('policies.claims')">
                        <span>Claims</span>
                    </Link>
                </div>

                <div class="navigation-child" :class="{active: route().current('policies.bonds')}">
                    <Link class="text-gray-700" :href="route('policies.bonds')">
                        <span>Surety Bonds</span>
                    </Link>
                </div>
                <div class="navigation-child" :class="{active: route().current('pay-my-bill')}">
                    <Link class="text-gray-700" :href="route('pay-my-bill')">
                        <span>Invoices</span>
                    </Link>
                </div>
            </div>

            <div class="navigation-link" :class="{active: route().current('programs')}">
                <Link class="text-gray-700" :href="route('programs')">
                    <FontAwesomeIcon fixed-width :icon="clipboard"/><span>Programs</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('users.index')}" v-if="['EM','AD'].some(r => $page.props.user.role_type == r)">
                <Link class="text-gray-700" :href="route('users.index')">
                    <FontAwesomeIcon fixed-width :icon="users"/><span>Manage Users</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('reports')}" v-if="['AD'].some(r => $page.props.user.role_type == r)">
                <Link class="text-gray-700" :href="route('reports')">
                    <FontAwesomeIcon fixed-width :icon="table"/><span>Reports</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('customers.index')}" v-if="['BR'].some(r => $page.props.user.role_type == r)">
                <Link class="text-gray-700" :href="route('customers.index')">
                    <FontAwesomeIcon fixed-width :icon="users"/><span>Customers</span>
                </Link>
            </div>
            <div class="navigation-link" :class="{active: route().current('pay-my-bill')}" v-if="['EM', 'AD', 'CN', 'BR'].some(r => $page.props.user.role_type == r) || $page.props.user.isImpersonating">
                <!--<Link class="text-gray-700" :href="route('policies')">-->
                <Link class="text-gray-700" :href="route('pay-my-bill')">
                    <FontAwesomeIcon fixed-width :icon="money"/><span>Pay Now</span>
                </Link>
            </div>
        </div>
    </nav>
</template>

<script>
import { faHouseUser, faPenAlt, faBook, faClipboard, faUsers, faTable, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Link } from '@inertiajs/inertia-vue3';

export default {
    name: "AppSidebar",
    components: {
        JetApplicationLogo,
        FontAwesomeIcon,
        Link,
    },
    data() {
        return {
            houseUser: faHouseUser,
            penAlt: faPenAlt,
            clipboard: faClipboard,
            book: faBook,
            users: faUsers,
            table: faTable,
            money: faMoneyBillWave,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .sidebar {
        position: sticky;
        top: var(--footer-height);
        transform: translateY(calc(-1 * var(--footer-height)));
        height: 100vh;
        grid-area: nav;
        z-index: 1;

        @include not-desktop {
            overflow: hidden;
            overflow-y: auto;
            position: fixed;
            height: 100vh;
            width: 100%;
            left: -100%;
            transition: 200ms ease-out;
            z-index: 3;

            .app.expanded & {
                left: 0;
            }
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: white;

            @include desktop {
                box-shadow: 2px 0 5px rgba(0,0,0,.1);
            }
        }

        .logo {
            grid-area: logo;
            padding: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            transition: 200ms ease-out;
            position: relative;

            :deep(svg) {
                @include fluid-size(70px, 100px, height);
                width: auto;
                transition: 200ms ease-out;
            }
        }

        .navigation {
            display: flex;
            flex-direction: column;

            .navigation-link {
                position: relative;

                span { transition: 200ms ease-out; }

                &:before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: white;
                    border-right: 0 solid transparent;
                    box-shadow: 0 0 0 rgba(0,0,0,0);
                    transition: 200ms ease-out;
                }

                &.active, &:hover {
                    &:before {
                        background-color: var(--gray-200);
                        box-shadow: 0 2px 5px rgba(0,0,0,.1);
                        border-right: 4px solid var(--teal);

                        @include desktop { width: calc(100% + 8px); }
                    }

                    a { color: var(--blue-500); }
                }

                a {
                    @include fluid-size(18px, 20px, font-size);
                    @include fluid-size(12px, 16px, padding-top);
                    @include fluid-size(12px, 16px, padding-bottom);
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    display: block;
                    font-weight: bold;
                    position: relative;
                    color: var(--gray-700);
                    transition: 200ms ease-out;

                    svg {
                        @include fluid-size(12px, 16px, margin-right);
                    }
                }

                &.child {
                    background-color: #F2F2F2;
                }
            }

            .navigation-child-group {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-color: #F2F2F2;
                    box-shadow: inset 0 0px 5px 2px rgb(0,0,0,.1);
                }
            }

            .navigation-child {
                position: relative;

                &.active, &:hover {
                    a { color: var(--blue-500); }
                }

                a {
                    @include fluid-size(16px, 18px, font-size);
                    @include fluid-size(8px, 16px, padding-top);
                    @include fluid-size(8px, 16px, padding-bottom);
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    display: block;
                    font-size: 22px;
                    font-weight: bold;
                    position: relative;
                    color: var(--gray-700);
                    transition: 200ms ease-out;
                }
            }
        }
    }
</style>
