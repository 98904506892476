<template>
    <AppLayout title="Claims">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'My View',route:'policies.policies'},'Claims']"/>
        </template>
        <ClaimsSearchSection
            v-if="entityId == 'NA' && ['EM','AD'].some(r => $page.props.user.role_type == r)"
            @selected-entity="setCompany($event)"
        />
        <ClaimsSection
            v-else
            :entity-id="entityId"
            @reset-entity="clearEntityId()"
        />
        <div class="mt-4 flex flex-wrap justify-end -m-4">
            <div class="p-4">
                <ClaimServicingSection :pdata="pdata"/>
            </div>

            <div class="p-4" v-if="userType=='CN'">
                <SubmitClaimSection :pdata="pdata"/> <!-- the form the section pops up is dependant on pdata for things like insured_name -->
            </div>
        </div>
    </AppLayout>
</template>

<script>
    import ClaimServicingSection from './Details/Sections/ClaimServicingSection.vue'
    import SubmitClaimSection from './Details/Sections/SubmitClaimSection.vue'
    import ClaimsSearchSection from './Sections/ClaimsSearchSection.vue'
    import ClaimsSection from './Sections/ClaimsSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        props:["pdata"],
        components: {
            ClaimServicingSection,
            SubmitClaimSection,
            ClaimsSection,
            ClaimsSearchSection,
            Breadcrumbs,
            AppLayout,
        },
        data() {
            return {
                company_entity_id: null,
            }
        },
        computed: {
            loginEntityId() {
                //if (this.$page.props.user.role_type == 'AD') return 33172100;
                let id = this.$page.props.user.user_entity_id;

                if(this.$page.props.user.role_type == 'AD' || this.$page.props.user.role_type == 'EM'){
                    return 'NA'
                } else {
                    return id !== 'NA' ? id : '';
                }
            },
            userType() {
                return this.$page.props.user.role_type;
            },
            entityId() {
                return this.company_entity_id || this.loginEntityId;
            }
        },
        methods: {
            setCompany(entity_id) {
                this.company_entity_id = entity_id;
            },
            clearEntityId() {
                this.company_entity_id = null;
            }
        }
    }
</script>
