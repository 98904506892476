<template>
    <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
        <div class="loader" v-if="loading"></div>
        <template v-else>
            <h3 class="text-blue-500">Submit a Claim </h3>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-800 my-0">{{validate_msg}}</p>
            </div>
            <div v-if="error_msg != ''" class="bg-red-100 p-2">
                <p class="text-red-800 my-0">{{error_msg}}</p>
            </div>
             <h6 class="mt-2 text-blue-500">Claim Contact Person</h6>

            <div class="md:flex -mb-2 -mx-2"> 
                <div class="m-2 flex-grow">
                    <JetLabel for="first_name" value="First Name" />
                    <JetInput id="first_name" type="text" class="mt-1 block w-full" v-model="form.requester_first_name" name="first_name" required/>
                 </div>
                 <div class="m-2 flex-grow">
                    <JetLabel for="last_name" value="Last Name" />
                    <JetInput id="last_name" type="text" class="mt-1 block w-full" v-model="form.requester_last_name" name="last_name" required/>
                 </div>
                <div class="m-2 flex-grow">
                    <JetLabel for="requester_email" value="Email" />
                    <JetInput type="email" class="mt-1 block w-full" v-model="form.requester_email" name="email" required/>
                 </div>
                 <div class="m-2 flex-grow">
                    <JetLabel for="mobile" value="Mobile" />
                    <JetInput id="mobile" type="text" class="mt-1 block w-full" v-model="form.requester_mobile" name="mobile" maxlength="11" required/>
                 </div>
            </div>

            <div class="mt-2">
                <JetLabel for="description" value="Brief Description of Facts" />
                <textarea id="description" name="description" class="flex-grow border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full" placeholder="Enter Brief Description of Facts"></textarea>
            </div>

            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow">
                    <SimpleDate label="Date of Loss" name="date_of_loss" v-model="form.date" required/>
                </div>
                <div class="m-2 flex-grow">
                    <SimpleSelect label="Type of Loss" name="type_of_loss" v-model="typeOfLossModel" required :options="typeOfLossOptions" />
                </div>
                <div class="m-2 flex-grow" v-if="typeOfLossModel=='Other'">
                    <JetLabel for="other_type" value="Please specify what type" />
                    <JetInput id="other_type" type="text" name="other_type" class="mt-1 block w-full" required/>
                </div>
            </div>

            <div class="mt-4 mb-2 flex flex-wrap items-center">
                <p class="font-medium text-blue-500 text-sm mr-4">Please upload all applicable documents including:</p>
                <p class="text-xs">
                    Incident&nbsp;Reports
                    <span class="mx-2">|</span>
                    Attorney&nbsp;Letter
                    <span class="mx-2">|</span>
                    Summons&nbsp;/&nbsp;Complaints
                    <span class="mx-2">|</span>
                    Patient&nbsp;Records&nbsp;/&nbsp;Files
                </p>
            </div>

            <div class="mt-2">
                <JetLabel for="fileInput" value="Upload Documents" />
                <SimpleFileUploader id="fileInput" v-model="form.files" />
            </div>
            <div class="flex flex-wrap mt-2 -mx-2 -mb-2">
                <button type="submit" class="btn-green m-2">Submit Claim</button>
                <button @click.prevent="closeModal()" class="btn-outline-gray m-2">Cancel</button>
                <button @click.prevent="reset()" class="btn-outline-gray m-2">Reset</button>
            </div>
        </template>
    </form>
</template>

<script>
import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue'
import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'

export default {
    mixins: [formProcessingMixin],
    props: ["pdata", "resetFormTrigger"],
    components: {
        SimpleFileUploader,
        SimpleSelect,
        SimpleDate,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            // just for passing user data by default, not for using with submission
            form: {
                date: '',
                files: []
            },
            validate_msg: '',
            loading: false,
            type_of_loss:'',
            typeOfLossModel: "",
            typeOfLossOptions: [
                { name: "", value: "", hidden: true },
                { name: "Injury", value: "Injury" },
                { name: "Property Damage", value: "Property Damage" },
                { name: "Other", value: "Other" }
            ],
            error_msg: '',
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'account_manager_email', value: this.pdata.account_manager_email },
                { key: 'account_manager_name',  value: this.pdata.account_manager_name },
                { key: 'policy_number',  value: this.pdata.policy_number },
                { key: 'insured_name',          value: this.pdata.insured_name }
            ]
        },
        filesData() {
            return [
                {name: 'files', list: this.form.files}
            ]
        }
    },
    methods: { 
        submitForm(event) {        
            this.submit(event, 'submitClaimForm', this.additionalData, {name: 'files', list: this.form.files});
        }
    },
    mounted() {
        let u = this.$page.props.user;
        this.form.requester_first_name = `${u.first_name}`;
        this.form.requester_last_name = `${u.last_name}`;
        this.form.requester_email = u.email;
        this.form.requester_mobile = u.mobile;
    }, 
    watch: {
        resetFormTrigger() {
            this.reset();
        }
    }
}
</script>