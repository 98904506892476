<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
        </div>
        <div class="simple-table" style="--cols:4">
            <p class="st-header first-col" :class="sortClass('full_name')" @click="sortBy({'full_name':['first_name','last_name']})">Name</p>
            <p class="st-header" :class="sortClass('email')" @click="sortBy('email')">Email</p>
            <p class="st-header" :class="sortClass('role_type')" @click="sortBy('role_type')">Role Type</p>
            <p class="st-header last-col">Actions</p>

            <template v-for="(row) in displayedList" :key="'manage_users_' + row.id">
                <p class="first-col"><span class="st-hidden">Name</span>{{ row.first_name }} {{ row.last_name }}</p>
                <p><span class="st-hidden">Email</span>{{ row.email }}</p>
                <p><span class="st-hidden">Role Type</span>{{ row.role_type }}</p>
                <div class="last-col">
                    <span class="st-hidden">Actions</span>
                    <div class="st-buttons">
                        <template v-if="!row.deleted_at">
                            <button @click="edit(row)" class="btn-blue" v-if="row.role_type != 'AD'">Edit</button>
                            <button @click="deleteRow(row)" class="btn-red" v-if="$page.props.user.id != row.id && row.role_type != 'AD'">Delete</button>
                            <button v-if="((row.impersonating == false && row.role_type != 'AD') || ($page.props.user.role_type == 'EM' && $page.props.user.role_type != row.role_type)) && $page.props.user.id != row.id && row.role_type != 'AD'" @click="impersonate(row)" class="btn-green">Impersonate</button>
                            <button v-if="row.impersonating == true" @click="exitimpersonate(row)" class="btn-green">Exit Impersonate</button>
                        </template>
                        <button v-else @click="restoreUser(row)" class="btn-red">Restore Deleted User</button>
                    </div>
                </div>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue';
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin],
        components: {
            SimpleTableWrapper,
        },
        methods: {
            edit(data) {
                this.$emit('edit', data);
            },
            deleteRow(data) {
                this.$emit('delete-row', data);
            },
            impersonate(data) {
                this.$emit('impersonate', data);
            },
            exitimpersonate(data) {
                this.$emit('exitimpersonate', data);
            },
            restoreUser(data) {
                this.$emit('restore-user', data);
            }
        }
    }
</script>
<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
