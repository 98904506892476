<template>
    <Card color="teal" title="Confirm Invoices to Pay" class="confirm-section">
        <p class="-mt-4 mb-4">Please confirm which invoices to pay.</p>

        <ConfirmInvoicesTable
            :data="invoices"
            @update-invoices="selectedInvoices($event)"
        />
    </Card>
</template>

<script>
import ConfirmInvoicesTable from '../Tables/ConfirmInvoicesTable.vue'
import JetInput from '@/Jetstream/Input.vue'
import Card from '@/Components/Card.vue'

export default {
    components: {
        ConfirmInvoicesTable,
        JetInput,
        Card,
    },
    data() {
        return {
            selected_invoices: [],
        }
    },
    emits: ['selected-invoices'],
    props: ['invoices'],
    methods: {
        selectedInvoices(event){
            this.selected_invoices = event;
            this.$emit('selected-invoices', this.selected_invoices);
        }
    }
}
</script>
