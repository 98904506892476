<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Policy No.</p>
            <p :class="sortClass('insured_name')" @click="sortBy('insured_name')" v-if="$page.props.user.role_type!='CN'">Insured</p>
            <p :class="sortClass('Contract_Set_Status')" @click="sortBy('Contract_Set_Status')">Status</p>
            <p :class="sortClass('Line_Code')" @click="sortBy('Line_Code')">Line of Business</p>
            <p :class="sortClass('Full_Term_Prem')" @click="sortBy('Full_Term_Prem','number')">Written Premium</p>
            <p :class="sortClass('Eff_Date')" @click="sortBy('Eff_Date','date')">Effective Date</p>
            <p :class="sortClass('Exp_Date')" @click="sortBy('Exp_Date','date')">Expiration</p>
        </div>
        <div class="simple-table" :style="[$page.props.user.role_type!='CN' && [$page.props.user.role_type]!='BR' ? '--cols:8' : '--cols:7']">
            <p class="st-header first-col" :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Policy No. </p>
            <p class="st-header" :class="sortClass('insured_name')" @click="sortBy('insured_name')" v-if="$page.props.user.role_type!='CN'">Insured</p>
            <p class="st-header" :class="sortClass('Contract_Set_Status')" @click="sortBy('Contract_Set_Status')">Status</p>
            <p class="st-header" :class="sortClass('Line_Code')" @click="sortBy('Line_Code')">Line of Business</p>
            <p class="st-header" :class="sortClass('Full_Term_Prem')" @click="sortBy('Full_Term_Prem','number')">Written Premium</p>
            <p class="st-header" :class="sortClass('Eff_Date')" @click="sortBy('Eff_Date','date')">Effective Date</p>
            <p class="st-header last-col" :class="sortClass('Exp_Date')" @click="sortBy('Exp_Date','date')">Expiration</p>

            <template v-for="row in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col">
                    <span class="st-hidden">Policy No.</span>
                    <Link class="underline" :href="route('policies.details', row.Contract_ID)">{{ row.Contract_Num }}</Link>
                </p>
                <p v-if="$page.props.user.role_type!='CN'">><span class="st-hidden">Insured</span>{{ row.insured_name }}</p>
                <p><span class="st-hidden">Status</span><Status :status="row.Contract_Set_Status" /></p>
                <p><span class="st-hidden">Line of Business</span>{{ row.Line_Code }}</p>
                <p><span class="st-hidden">Written Premium</span>{{ formatMoney(row.Full_Term_Prem) }}</p>
                <p><span class="st-hidden">Effective Date</span>{{ formatDate(row.Eff_Date) }}</p>
                <p class="last-col"><span class="st-hidden">Expiration</span>{{ formatDate(row.Exp_Date) }}</p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<style lang="scss" scoped>
    @import '../../../../../scss/_mixins.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), auto);
    }
</style>
<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'

    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            Status,
            Link,
        },
        mounted:function(){
            this.sortBy('Contract_Set_Status')
        },
    }
</script>
