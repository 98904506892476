<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p>Add All</p>
            <p :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Invoice Status</p>
            <p :class="sortClass('Posted_Balance')" @click="sortBy('Posted_Balance','number')">Invoice Amount</p>
            <p :class="sortClass('Due_Date')" @click="sortBy('Due_Date','date')">Due Date</p>
            <p :class="sortClass('Policy_Number')" @click="sortBy('Policy_Number')">Policy No.</p>
            <p :class="sortClass('Contract_Status')" @click="sortBy('Contract_Status')">Policy Status</p>
            <p :class="sortClass('Effective_Date')" @click="sortBy('Effective_Date','date')">Effective Date</p>
            <p :class="sortClass('Expiration_Date')" @click="sortBy('Expiration_Date','date')">Expiration</p>
        </div>
        <div class="simple-table" style="--cols:8">
            <p class="st-header first-col" ><label class="inline"><input type="checkbox" id="addAllCheckbox" @click="addAllInvoices($event)"> Add All</label></p>
            <p class="st-header" :class="sortClass('Contract_Num')" @click="sortBy('Contract_Num')">Invoice Status</p>
            <p class="st-header" :class="sortClass('Posted_Balance')" @click="sortBy('Posted_Balance','number')">Invoice Amount</p>
            <p class="st-header" :class="sortClass('Due_Date')" @click="sortBy('Due_Date','date')">Due Date</p>
            <p class="st-header" :class="sortClass('Policy_Number')" @click="sortBy('Policy_Number')">Policy No.</p>
            <p class="st-header" :class="sortClass('Contract_Status')" @click="sortBy('Contract_Status')">Policy Status</p>
            <p class="st-header" :class="sortClass('Effective_Date')" @click="sortBy('Effective_Date','date')">Effective Date</p>
            <p class="st-header last-col" :class="sortClass('Expiration_Date')" @click="sortBy('Expiration_Date','date')">Expiration</p>

            <template v-for="(row,index) in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col">
                    <!-- ['Processed','Pending'] -->
                    <span v-if="parseFloat(row.Posted_Balance) > 0 && ['Open'].includes(determineStatus(row).trim())">
                        <span class="st-hidden">Add</span>
                        <label class="inline">
                            <input :id="'ai_'+row.Contract_ID" :data-index="index" class="addInvoiceCheckbox" type="checkbox" @click="addInvoice(row, $event)"> 
                            <span class="underline">Add</span>
                        </label>
                    </span>
                </p>
                <p><span class="st-hidden">Invoice Status</span><Status :status="determineStatus(row)" /></p>
                <p><span class="st-hidden">Invoice Amount</span>{{ formatMoney(row.Posted_Balance) }}</p>
                <p><span class="st-hidden">Due Date</span>{{ formatDate(row.Due_Date) }}</p>
                <p><span class="st-hidden">Policy No.</span>{{ row.Contract_Number }}</p>
                <p><span class="st-hidden">Policy Status</span>{{ row.Contract_Status }}</p>
                <p><span class="st-hidden">Effective Date</span>{{ formatDate(row.Effective_Date) }}</p>
                <p><span class="st-hidden">Expiration</span>{{ formatDate(row.Expiration_Date) }}</p>
            </template>
        </div>
    </SimpleTableWrapper>

</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import { faMinus } from '@fortawesome/free-solid-svg-icons'
    import { faPlus } from '@fortawesome/free-solid-svg-icons'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'
    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            Status,
            Link,
        },
        emits: ['selected-invoices'],
        props: ['paid', 'pending'],
        data() {
            return {
                activePolicy: null,
                activePolicyDetails: null,
                activePolicyLoading: false,
                activePolicyIndex: null,
                claimServiceModalVisible: false,
                entity_ids: this.$page.props.user.login_entity_ids,
                invoices: [],
                selectedInvoices: [],
            }
        },
        watch: {
            activePolicy(n) {
                if (n) {
                    this.activePolicyLoading = true;
                    this.activePolicyDetails = null;

                    this.loading = true,
                    axios.post('/policies/pdata',
                        {
                            'entity_id': n
                        })
                        .then( response =>  {
                            this.activePolicyLoading = false,
                            this.activePolicyDetails = response.data.pdata
                    })

                    this.activePolicyLoading = false,
                    this.activePolicyDetails = n

                } else {
                    this.activePolicyDetails = null;
                }
            },
            displayedList: {
                handler() {
                    this.activePolicy = null;
                    this.activePolicyDetails = null;
                },
                deep: true
            }
        },
        computed: {
            getGridArea() {
                return `--grid-position:${this.activePolicyIndex+3};--grid-mobile-position: ${(this.activePolicyIndex*7)+4}`;
            },
        },
        methods: {
            setActivePolicy(policyId, index) {
                this.activePolicy = this.activePolicy !== policyId ? policyId : null;
                this.activePolicyIndex = index;
            },
            addInvoice(row, $event){
                var checked = $event.target.checked;
                if(checked){
                    this.selectedInvoices.push(row);
                    this.$emit('selected-invoices', this.selectedInvoices);
                } else {
                    this.selectedInvoices.splice(this.invoices.indexOf(row), 1);
                    this.$emit('selected-invoices', this.selectedInvoices);
                }
            },
            addAllInvoices(e) {
                var $vr = this;
                var allChecks = document.querySelectorAll('.addInvoiceCheckbox');
                var unchecked = [].filter.call(allChecks, function( el ) {
                    return !el.checked
                })
                var checked = [].filter.call(allChecks, function( el ) {
                    return el.checked
                })
                if(e.target.checked){
                    //loop through unchecked and check them
                    unchecked.forEach(function(item, index) {
                        item.checked = true;
                        var rowData = item.getAttribute('data-index');

                        $vr.selectedInvoices.push($vr.displayedList[rowData]);
                    })
                    $vr.$emit('selected-invoices', $vr.selectedInvoices);
                } else {
                    //loop through checked and uncheck them
                    $vr.selectedInvoices = [];
                    checked.forEach(function(item, index) {
                        item.checked = false;
                    })
                    $vr.$emit('selected-invoices', $vr.selectedInvoices);
                }
                if(!allChecks.length > 0){
                    // no checkboxes to check, uncheck the Add All checkbox
                    document.getElementById('addAllCheckbox').checked = false;

                }
            },
            determineStatus(invoice) {
                var status = ' Open';
                if(invoice.Posted_Balance > 0) {
                    // if(invoice.Contract_Status == 'Expired') {
                    //     var status = ' Closed';
                    // }
                    if(this.pending.includes(invoice.Entity_Ledger)) {
                        status = ' Pending';
                    }
                    if(this.paid.includes(invoice.Entity_Ledger)) {
                        status = ' Processed';
                    }
                } else {
                    if(invoice.Contract_Status == 'Expired') {
                        var status = ' Closed';
                    }
                }

                return status;
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import '../../../../scss/_mixins.scss';

    .simple-table {
        --custom-col-structure: repeat(var(--cols), 1fr);
    }

    .dropdown-panel {
        position: relative;
        overflow: hidden;
        transition: 200ms ease-out;
        display: block !important;
        grid-column: 1 / -1;

        @include bp-up($bp-xl + 1) { grid-row: var(--grid-position) / span 1; }
        @include bp-down($bp-xl) { grid-row: var(--grid-mobile-position) / span 1; }

        &.loading {
            padding-top: 50px !important;
            padding-bottom: 50px !important;
        }

        &:not(.active) {
            height: 0;
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            opacity: 0;
        }

        &.active {
            height: auto;
            padding: 1rem;
            box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
        }
    }
    .expand-details {
        cursor: pointer;
    }
</style>
