<template>
    <AppLayout title="Policies">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'My View','Policies']"/>
        </template>
        <PoliciesSearchSection
            v-if="entityId == 'NA' && ['EM','AD'].some(r => $page.props.user.role_type == r)"
            @selected-entity="setCompany($event)"
        />
        <PoliciesSection
            v-else
            :entity-id="entityId"
            @reset-entity="clearEntityId()"
        />
    </AppLayout>
</template>

<script>
    import PoliciesSection from './Sections/PoliciesSection.vue'
    import PoliciesSearchSection from './Sections/PoliciesSearchSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        components: {
            PoliciesSection,
            PoliciesSearchSection,
            Breadcrumbs,
            AppLayout,
        },
        data() {
            return {
                company_entity_id: null,
            }
        },
        computed: {
            loginEntityId() {
                //if (this.$page.props.user.role_type == 'AD') return 16671100; // Returns Spooners Inc records
                let id = this.$page.props.user.login_entity_ids;
                if(this.$page.props.user.role_type == 'AD' || this.$page.props.user.role_type == 'EM'){
                    return 'NA'
                } else {
                    return id !== 'NA' ? id : '';
                }
            },
            entityId() {
                return this.company_entity_id || this.loginEntityId;
            }
        },
        methods: {
            setCompany(entity_id) {
                this.company_entity_id = entity_id;
            },
            clearEntityId() {
                this.company_entity_id = null;
            }
        }
    }
</script>
