<template>
    <app-layout title="Profile">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Profile']"/>
        </template>

        <div class="profile-cards">
            <div class="profile-card" v-if="canUpdateProfileInformation">
                <Card color="blue-500" title="Profile Information" class="h-full">
                    <div class="flex flex-col h-full">
                        <p class="-mt-4 mb-4">Update your profile information and email address.</p>
                        <div class="flex-grow">
                            <UpdateProfileInformationForm :user="user" />
                        </div>
                    </div>
                </Card>
            </div>

            <div class="profile-card" v-if="canUpdatePassword">
                <Card color="green-500" title="Update Password" class="h-full">
                    <div class="flex flex-col h-full">
                        <p class="-mt-4 mb-4">Please use a long, random password to stay secure.</p>
                        <p class="text-xs text-gray-500">Your password must contain at least:</p>
                        <ul class="list-disc text-gray-500 text-xs pl-4 mb-2">
                            <li>8 characters</li>
                            <li>1 English lowercase letter (a-z)</li>
                            <li>1 English uppercase letter (A-Z)</li>
                            <li>1 number (0-9)</li>
                            <li>1 symbol (#%@!?$&amp; etc...)</li>
                        </ul>
                        <div class="flex-grow">
                            <UpdatePasswordForm />
                        </div>
                    </div>
                </Card>
            </div>

            <div class="profile-card">
                <Card color="gray-500" title="Browser Sessions">
                    <div class="flex flex-col h-full">
                        <p class="-mt-4 mb-4">Manage and log out your active sessions on other browsers and devices.</p>
                        <div class="flex-grow">
                            <LogoutOtherBrowserSessionsForm :sessions="sessions" />
                        </div>
                    </div>
                </Card>
            </div>

            <div class="profile-card" v-if="canManageTwoFactorAuthentication && $page.props.user.role_type != 'AD'">
                <Card color="teal" title="Two Factor Authentication">
                    <div class="flex flex-col h-full">
                        <p class="-mt-4 mb-4">Add additional security to your account using two factor authentication.</p>
                        <div class="flex-grow">
                            <TwoFactorAuthenticationForm />
                        </div>
                    </div>
                </Card>
            </div>

            <!-- Hidden for now -->
            <!-- <div class="profile-card" v-if="hasAccountDeletionFeatures">
                <Card color="red" title="Delete Account">
                    <div class="flex flex-col h-full">
                        <p class="-mt-4 mb-4">Permanently delete your account.</p>
                        <div class="flex-grow">
                            <DeleteUserForm />
                        </div>
                    </div>
                </Card>
            </div> -->
        </div>
    </app-layout>
</template>

<script>
    import LogoutOtherBrowserSessionsForm from '@/Pages/Profile/Partials/LogoutOtherBrowserSessionsForm.vue'
    import UpdateProfileInformationForm from '@/Pages/Profile/Partials/UpdateProfileInformationForm.vue'
    import TwoFactorAuthenticationForm from '@/Pages/Profile/Partials/TwoFactorAuthenticationForm.vue'
    import UpdatePasswordForm from '@/Pages/Profile/Partials/UpdatePasswordForm.vue'
    import DeleteUserForm from '@/Pages/Profile/Partials/DeleteUserForm.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            LogoutOtherBrowserSessionsForm,
            UpdateProfileInformationForm,
            TwoFactorAuthenticationForm,
            UpdatePasswordForm,
            DeleteUserForm,
            Breadcrumbs,
            AppLayout,
            Card,
        },
        props: ['sessions'],
        data() {
            return {
                canManageTwoFactorAuthentication: this.$page.props.jetstream.canManageTwoFactorAuthentication,
                canUpdateProfileInformation: this.$page.props.jetstream.canUpdateProfileInformation,
                hasAccountDeletionFeatures: this.$page.props.jetstream.hasAccountDeletionFeatures,
                canUpdatePassword: this.$page.props.jetstream.canUpdatePassword,
                user: this.$page.props.user,
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .profile-cards {
        column-gap: 0;

        @include bp-up($bp-xl + 1) { column-count: 2; }
        @include bp-down($bp-xl) { column-count: 1; }

        .profile-card {
            break-inside: avoid;
            padding: 1rem;
        }
    }
</style>
