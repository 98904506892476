<template>
    <!-- The click event on the wrapper is there to catch when people click off of the modal, so when you click away the modal goes away -->
    <!--<div class="modal-wrapper" :class="{'modal-hidden': !visible}" @click.self="emitClose"> -->
    <div class="modal-wrapper" :class="{'modal-hidden': !visible}" @click.self="isOpen = false;">
        <div class="modal-object" :style="{width: width}">
            <div class="modal-close" @click="emitClose"></div>
            <div class="modal-body">
                <slot />
            </div>
            <div class="modal-footer" v-if="hasFooter">
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SimpleModal",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '400px'
        }
    },
    emits: [
        "on-close"
    ],
    computed: {
        hasFooter() {
            return this.$slots.hasOwnProperty('footer');
        }
    },
    methods: {
        emitClose() {
            this.$emit('on-close');
        }
    },
    watch: {
        visible: function(n,o) {
            if (n) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.removeProperty('overflow');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    $closeSize: 12px;
    $borders: 1px solid #ddd;

    .modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        z-index: 200;
        opacity: 1;
        pointer-events: all;
        transition: 200ms ease-out;

        &.modal-hidden {
            opacity: 0;
            pointer-events: none;

            // somehow textarea resize handles keep their pointer-events
            // even while their modals are hidden, very weird
            :deep(textarea) { resize: none; }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(black, .53);
        }
    }

    .modal-object {
        position: relative;
        background-color: white;
        border-radius: 0;
        display: grid;
        max-width: calc(100% - 2rem);
        max-height: calc(100% - 2rem);
        height: min-content;
        overflow: hidden;
        grid-template:
            ". c" max-content
            "b b" 1fr
            "f f" max-content
            / 1fr ($closeSize + 32px);
    }

    .modal-footer {
        grid-area: f;
        padding: 1rem;
    }
    .modal-close {
        grid-area: c;
        padding: 1rem;
        position: relative;
        border-left: $borders;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            height: 3px;
            width: 3px;
            position: absolute;
            background-color: mix(white, black, 50%);
            top: 50%;
            left: 50%;
            transform-origin: 50% 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &:before { width: $closeSize; }
        &:after { height: $closeSize; }
    }

    .modal-body {
        grid-area: b;
        padding: 1rem;
        border-top: $borders;
        border-bottom: $borders;
        height: 100%;
        overflow-y: auto;
    }
</style>