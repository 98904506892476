<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('company_name')" @click="sortBy('company_name')">Company Name</p>
            <p :class="sortClass('address_line_1')" @click="sortBy('address_line_1')">Address</p>
            <p :class="sortClass('city')" @click="sortBy('city')">City</p>
            <p :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p :class="sortClass('zip')" @click="sortBy('zip')">Zip</p>
            <p :class="sortClass('county')" @click="sortBy('county')">County</p>
        </div>
        <div class="simple-table" style="--cols:6">
            <p class="st-header first-col" :class="sortClass('company_name')" @click="sortBy('company_name')">Company Name</p>
            <p class="st-header" :class="sortClass('address_line_1')" @click="sortBy('address_line_1')">Address</p>
            <p class="st-header" :class="sortClass('city')" @click="sortBy('city')">City</p>
            <p class="st-header" :class="sortClass('state')" @click="sortBy('state')">State</p>
            <p class="st-header" :class="sortClass('zip')" @click="sortBy('zip')">Zip</p>
            <p class="st-header last-col" :class="sortClass('county')" @click="sortBy('county')">County</p>
            <template v-for="row in displayedList" :key="'pending_invite_' + row.id">
                <p class="first-col">
                    <span class="st-hidden">Company Name</span>
                    {{ row.company_name }}
                </p>
                <p>
                    <span class="st-hidden">Address</span>
                    {{ row.address_line_1 }}
                </p>
                <p>
                    <span class="st-hidden">City</span>
                    {{ row.city }}
                </p>
                <p>
                    <span class="st-hidden">State</span>
                    {{ row.state }}
                </p>
                <p>
                    <span class="st-hidden">Zip</span>
                    {{ row.zip }}
                </p>
                <p class="last-col">
                    <span class="st-hidden">County</span>
                    {{ row.county }}
                </p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>
'company_name', 'address_line_1', 'city', 'state', 'zip', 'county'
<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'
    import { Link } from '@inertiajs/inertia-vue3'
    import Status from '@/Components/Status.vue'

    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            Status,
            Link,
        },
        emits: ['selected-entity'],
        methods: {
            selectEntity(entity) {
                this.$emit('selected-entity', entity);
            }
        },
    }
</script>
