<template>
    <Head title="Register" />

    <SplashScreen />

    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <JetValidationErrors class="mb-4" />
        <span v-if="!this.data.islinkExpired">
            <form @submit.prevent="submit">
                <div>
                    <JetLabel for="name" value="First Name" />
                    <JetInput id="first_name" :readonly="this.data.i_first_name" name="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" required autofocus autocomplete="first_name" />
                </div>

                <div class="mt-4">
                    <JetLabel for="name" value="Last Name" />
                    <JetInput id="last_name" :readonly="this.data.i_last_name" ref="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" autofocus autocomplete="last_name" />
                </div>

                <div class="mt-4">
                    <JetLabel for="email" value="Email" />
                    <JetInput id="email" :readonly="this.data.i_email" ref="email" type="email" class="mt-1 block w-full" v-model="form.email" required />
                </div>

                <div class="mt-4">
                    <SimplePassword v-model="form.password" autocomplete="new-password" />
                </div>

                <div class="mt-4">
                    <SimplePassword v-model="form.password_confirmation" label-value="Confirm Password" autocomplete="new-password" />
                </div>

                <div class="mt-4">
                    <div class="g-recaptcha" id="rcaptcha" :data-sitekey="this.data.google_sitekey" data-callback="onSubmit"  data-size="invisible"></div>
                    <span id="captcha" style="color:red"></span>
                </div>

                <div class="mt-4" v-if="$page.props.jetstream.hasTermsAndPrivacyPolicyFeature">
                    <JetLabel for="terms">
                        <div class="flex items-center cursor-pointer" @click="termsModalVisible = true">
                            <JetCheckbox class="pointer-events-none" name="terms" id="terms" v-model:checked="form.terms" :disabled="!form.terms" required />

                            <p class="ml-2">{{ form.terms ? 'I have read and agree to the' : 'Please read and agree to our' }} Terms of Service and Privacy Policy</p>
                        </div>
                    </JetLabel>
                </div>

                <div class="flex items-center justify-end mt-4">
                    <Link :href="route('login')" class="underline text-sm text-gray-600 hover:text-gray-900">
                        Already registered?
                    </Link>

                    <JetButton class="ml-4" :class="{ 'opacity-25': registerBtnDisabled }" :disabled="registerBtnDisabled">
                        Register
                    </JetButton>
                </div>
            </form>
        </span>
        <span v-if="this.data.islinkExpired">
            <h6>{{this.data.error_message}}</h6>
        </span>
    </JetAuthenticationCard>

    <SimpleModal :visible="termsModalVisible" @on-close="termsModalVisible = false">
        <h5>View Our Terms of Service &amp; Privacy Policy</h5>
        <div class="flex flex-wrap -mx-1 my-2">
            <a class="btn-blue m-1 flex-grow text-center" href="https://www.vgminsurance.com/terms-of-use" title="Opens our terms of service in a new tab" target="_blank">Terms of Service</a>
            <a class="btn-blue m-1 flex-grow text-center" href="https://www.vgminsurance.com/privacy-policy" title="Opens our privacy policy in a new tab" target="_blank">Privacy Policy</a>
        </div>

        <div class="mt-3 pt-3 flex items-center border-t border-gray-400 cursor-pointer" @click.prevent="hasReadAndAcceptedTermsAndPolicies()">
            <JetCheckbox class="pointer-events-none" v-model:checked="form.terms" disabled/>
            <p class="ml-3 text-sm leading-tight">I have read and agree to the Terms of Service and the Privacy Policy.</p>
        </div>
    </SimpleModal>

</template>

<script>
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SimplePassword from '@/Components/FormElements/SimplePassword.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import SplashScreen from '@/Components/SplashScreen.vue'
    import SimpleModal from '@/Components/SimpleModal.vue'
    import { Head, Link } from '@inertiajs/inertia-vue3'
    import JetCheckbox from '@/Jetstream/Checkbox.vue'
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetAuthenticationCardLogo,
            JetAuthenticationCard,
            JetValidationErrors,
            SimplePassword,
            SplashScreen,
            JetCheckbox,
            SimpleModal,
            JetButton,
            JetLabel,
            JetInput,
            Head,
            Link,
        },
        props: ['data', 'errors'],
        data() {
            return {
                termsModalVisible: false,
                form: this.$inertia.form({
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    terms: false,
                })
            }
        },
        computed: {
            registerBtnDisabled() {
                return !this.form.terms || this.form.processing;
            }
        },
        beforeMount: function() {
          this.form.first_name = this.data.i_first_name
          this.form.last_name = this.data.i_last_name
          this.form.email = this.data.i_email
        },
        mounted() {
            let recaptchaScript = document.createElement('script')
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
            document.head.appendChild(recaptchaScript)
            recpatchaKey: this.data.google_sitekey
        },
        methods: {
            submit() {
                this.form.post(this.route('register'), {
                    onFinish: () => this.form.reset('password', 'password_confirmation'),
                })
            },
            hasReadAndAcceptedTermsAndPolicies() {
                this.form.terms = true;
                this.termsModalVisible = false;
            }
        }
    }
</script>
