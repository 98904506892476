<template>
    <Card color="teal" title="Customers">
        <p class="-mt-4 mb-4">Search for a VGM Insurance Customer to view their policies.</p>

        <div class="loader" v-if="loading"></div>

        <form class="d-flex flex-wrap align-items-center justify-content-between" @submit.prevent="formSubmit" enctype="multipart/form-data" v-show="$page.props.user.role_type == 'AD' || $page.props.user.role_type == 'EM'">
            <div class="flex align-stretch">
                <JetInput class="flex-grow-0" type="text" v-model="form.entity_name" placeholder="Search by Company Name..." required  maxlength="55"/>
                <button  class="btn-blue flex-grow-0 rounded-l-none ml-2">Submit</button>
            </div>
        </form>

        <PoliciesSearchTable
            v-if="search_data.length"
            class="mt-6"
            :data="search_data"
            :searchable-data="['company_name']"
            @selected-entity="selectEntity($event)"
            search-placeholder="Search by Company..."
        />
        <p v-if="!search_data.length && form.entity_name && !loading" class="mt-4 bg-gray-200 p-4">No customers found</p>
    </Card>
</template>

<script>
import PoliciesSearchTable from '../Tables/PoliciesSearchTable.vue'
import JetInput from '@/Jetstream/Input.vue'
import Card from '@/Components/Card.vue'

export default {
    components: {
        PoliciesSearchTable,
        JetInput,
        Card,
    },
    props: {
        entityId: [String]
    },
    emits: ['selected-entity'],
    data() {
        return {
            form: {
                entity_name: null,
            },
            loading: false,
            search_data: [],
        };
    },
    mounted() {
        if (this.entityId) {
            this.form.entity_name = this.entityId;
            this.formSubmit();
        }
    },
    methods: {
        formSubmit() {
            this.loading = true,
            axios.post('/policies/company_search', this.form)
                .then( response =>  {
                    this.loading = false,
                    this.search_data = [];
                    // Loop through array result
                    response.data.search_results.forEach(result => {
                        // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                        this.search_data.push(Object.freeze(result));
                    });
            })
        },
        selectEntity(entity) {
            this.$emit('selected-entity', entity);
        },
    }
}
</script>
