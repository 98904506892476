<template>
    <Card color="blue-500" title="Submit a Claim" class="cta-cover cursor-pointer relative">
        <p>Click here to start the process of submitting a new claim.</p>

        <div class="absolute-fill" @click.self="claimServiceModalVisible = true"></div>

        <template #cta>
            <div class="cta-box">
                <FontAwesomeIcon :icon="chevronRight" />
            </div>
        </template>

        <SimpleModal :visible="claimServiceModalVisible" @on-close="closeModal()" width="1000px">
            <SubmitClaimModalContent @on-close="closeModal()" :reset-form-trigger="resetFormTrigger" :pdata="pdata"/>
        </SimpleModal>
    </Card>
</template>

<script>
import SubmitClaimModalContent from './SubmitClaimModalContent.vue'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SimpleModal from '@/Components/SimpleModal.vue'
import Card from '@/Components/Card.vue'

export default {
    props: ["pdata"],
    components: {
        SubmitClaimModalContent,
        FontAwesomeIcon,
        SimpleModal,
        Card,
    },
    data() {
        return {
            claimServiceModalVisible: false,
            chevronRight: faChevronRight,
            resetFormTrigger: false
        }
    },
    methods: {
        closeModal() {
            this.claimServiceModalVisible = false;
            this.resetFormTrigger = !this.resetFormTrigger;
        }
    }
}
</script>