<template>
    <Card color="teal" title="Payment Method" class="payment-method-section">
        <p class="-mt-4 mb-4">Please confirm how you wish to complete this transaction.</p>
        <form class="d-flex flex-wrap align-items-center justify-content-between" @submit.prevent="formSubmit" enctype="multipart/form-data">
            <input id="ach" type="radio" name="paymentMethod" v-model="paymentMethod" value="ach" @change="updatePaymentMethod"/><FontAwesomeIcon :icon="creditCard" class="mr-2 ml-2"  /><span class="ml-3 leading-tight">ACH   (A $4 flat fee will be applied)</span><br/>
            <input id="cc" type="radio" name="paymentMethod" v-model="paymentMethod" value="cc" @change="updatePaymentMethod"/><FontAwesomeIcon :icon="cashSymbol" class="mr-2 ml-2"  /><span class="ml-3 leading-tight">Credit Card    (A 3% convenience fee will be applied)</span>
        </form>
        <h4 data-v-24549c4a="">Payment Method Details</h4>
        <p class="mb-4">Please provice the appropriate payment details.</p>
        <CreditCard
            v-if="paymentMethod == 'cc'"
            :invoices="invoices"
            @refresh="refresh($event)"
        />
        <ACH
            v-if="paymentMethod == 'ach'"
            :invoices="invoices"
            @refresh="refresh($event)"
        />
    </Card>
</template>

<script>

import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetInput from '@/Jetstream/Input.vue'
import Card from '@/Components/Card.vue'
import CreditCard from '../Partials/CreditCard.vue'
import ACH from '../Partials/ACH.vue'

export default {
    components: {
        FontAwesomeIcon,
        JetCheckbox,
        JetInput,
        Card,
        CreditCard,
        ACH,
    },
    emits: ['payment-method', 'refresh'],
    props: ['invoices'],
    data() {
        return {
            paymentMethod: null,
            loading: false,
            creditCard: faCreditCard,
            cashSymbol: faMoneyBillWave,
            paymentMethod: null,
        };
    },
    computed: {
        invoicesTotal() {
            var total = 0.00;
            for (const listItem of this.invoices) {
                total = total + listItem.Posted_Balance;
            }
            return total;
        }
    },
    methods: {
        refresh(event) {
            this.$emit('refresh', event);
        },
        formSubmit() {
            this.loading = true,
            axios.post('/policies/search', this.form)
                .then( response =>  {
                    this.loading = false,
                    this.search_data = [];
                    // Loop through array result
                    response.data.search_results.forEach(result => {
                        // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                        this.search_data.push(Object.freeze(result));
                    });
            })
        },
        updatePaymentMethod($event) {
            this.$emit('payment-method', $event.target.id);
        },
        formSubmit() {
            this.loading = true,
            axios.post('/pay-bill', this.form)
                .then( response =>  {
                    //success
            })
        },
        reset() {
            this.$emit('reset-entity');
        }
    }
}
</script>
