<template>
    <app-layout title="Dashboard">
        <template #breadcrumbs>
            <Breadcrumbs :path="['Dashboard']" />
        </template>

        <div class="card-grid">
            <Card
                v-if="['EM','AD', 'CN'].some(r => $page.props.user.role_type == r)"
                color="teal"
                title="My Policies"
                cta-route="policies.policies"
                cta-cover
                image="/images/dashboard-policies.jpg"
            ><p>View the policies you have in place with VGM Insurance.</p></Card>

            <Card
                v-if="['BR'].some(r => $page.props.user.role_type == r)"
                color="teal"
                title="My Customers"
                cta-route="customers.index"
                cta-cover
                image="/images/dashboard-policies.jpg"
            ><p>View the current customers you have in place with VGM Insurance.</p></Card>

            <Card
                color="yellow"
                title="My Profile"
                cta-route="profile.show"
                cta-cover
                image="/images/dashboard-profile.jpg"
            ><p>View your account details and make any necessary updates.</p></Card>

            <Card
                v-if="['EM','AD','CN'].some(r => $page.props.user.role_type == r)"
                color="blue-500"
                title="My Claims"
                cta-route="policies.claims"
                cta-cover
                image="/images/dashboard-claims.jpg"
            ><p>View the status of your recently submitted claims.</p></Card>

        </div>
    </app-layout>
</template>

<script>
    import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
    import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Welcome from '@/Jetstream/Welcome.vue'
    import Card from '@/Components/Card.vue';

    export default {
        components: {
            FontAwesomeIcon,
            Breadcrumbs,
            AppLayout,
            Welcome,
            Card,
        },
        data() {
            return {
                showAllVideos: false,
                chevronDown: faChevronDown,
                chevronUp: faChevronUp,
                playCircle: faPlayCircle
            }
        },
        methods: {
            showVideo(id) {
                alert('Once this is set up to pull in dynamically, this should show video ' + id);
            }
        },
        computed: {
            userType() {
                return this.$page.props.user.role_type;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .card-grid {
        margin: -1rem;
        display: grid;

        @include desktop {
            grid-template:
                "t y g" max-content
                "p p m" max-content
                / 1fr 1fr 1fr;
        }

        @include not-desktop {
            grid-template:
                "t" max-content
                "y" max-content
                "g" max-content
                "p" max-content
                "m" max-content
                / 100%;
        }

        .card:nth-of-type(1) { grid-area: t; }
        .card:nth-of-type(2) { grid-area: y; }
        .card:nth-of-type(3) { grid-area: g; }


        .card {
            margin: 1rem;
        }

    }

    :deep(.video-grid) {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 1rem -1rem -1rem;
        transition: 200ms ease-out;

        &:not(.expanded) {
            overflow: hidden;
            height: calc(300px + min(20vw, 100px));

            &:before {
                background-image: linear-gradient(to top, white, transparent);
            }

        }

        &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 40px;
            width: 100%;
            background-image: linear-gradient(to top, transparent, transparent);
            z-index: 1;
        }

        .video {
            display: flex;
            flex-direction: column;
            margin: 1rem;
            flex: 1 0 calc(25% - 2rem);
            min-width: 250px;
            box-shadow: 6px 6px 6px rgba(0,0,0,.1);
            background-color: white;
            cursor: pointer;

            .thumbnail {
                font-size: 420%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 100%;
                aspect-ratio: 16 / 9;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                svg {
                    color: rgba(white,.75);
                    text-shadow: 6px 6px 6px rgba(0,0,0,.1);
                    position: relative;
                }
            }

            .title {
                @include fluid-size(16px, 20px, font-size);
                padding: .5rem 1rem .25rem;
                font-weight: 600;
                line-height: 1.25
            }

            .description {
                line-height: 1.25;
                font-weight: 100;
                padding: 0 1rem .5rem;
                color: var(--gray-500);
            }
        }
    }
</style>
