<template>
    <app-layout title="Search &amp; Invite">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},{name:'Manage Users',route:'users.index'},'Search &amp; Invite']"/>
        </template>

        <Card color="gray-500" title="Search &amp; Invite">
            <div class="loader" v-if="loading"></div>

            <!--success/error message-->
            <div v-if="success">
                <div class="mt-4 mb-3 font-medium text-lg text-green-600">
                    {{success}}
                </div>
            </div>
            <!--message code end-->

            <form @submit.prevent="formSubmit" enctype="multipart/form-data">
                <div class="inline-flex align-stretch">
                    <JetInput type="text" v-model="form.email" placeholder="Enter Email..."/>
                    <button class="btn-blue rounded-l-none">Submit</button>
                </div>
                <!--<div v-if="$page.errors.Email" class="text-red-500">{{ $page.errors.Email[0] }}</div>-->
            </form>

            <!-- This will eventually move to it's own table component -->
            <SearchInviteTable
                class="mt-6"
                :cols="4"
                v-if="search_data.length"
                :data="search_data"
                :searchable-data="['first_name', 'last_name', 'email', 'role_type']"
                @send-invitation="sendInvitation($event)"
            /> 
        </Card>
        <h5 class="mt-4" v-if="noresults_msg">No users found</h5>
    </app-layout>
</template>

<script>
    import SearchInviteTable from './Tables/SearchInviteTable.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'
    import Welcome from '@/Jetstream/Welcome.vue'
    import JetLabel from '@/Jetstream/Label.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            SearchInviteTable,
            Breadcrumbs,
            AppLayout,
            JetLabel,
            JetInput,
            Welcome,
            Card,
        },
        props: ['data', 'errors'],
        data() {
            return {
                form: {
                    email: null,
                    first_name: null,
                    last_name: null,
                    role_type: null
                },
                success: '',
                loading: false,
                elementVisible: false,
                sendInvitationBtn: false,
                showSearchUsers:false,
                search_data: [],
                noresults_msg:false,
            };
        },
        methods: {
            formSubmit(e) {
                this.loading = true,
                axios.post('/users/search', this.form)
                    .then( response =>  { 
                    this.loading = false,
                    this.showSearchUsers = true,
                    this.search_data = response.data.search_results
                    if(!this.search_data.length){
                        this.noresults_msg = true;
                    }else{
                        this.noresults_msg = false;
                    }
                })
            },
            sendInvitation({first_name,last_name,email,role_type}) {
                let vm = this;
                this.loading = true;
                const data = new FormData();
                data.append('first_name', first_name);
                data.append('last_name', last_name);
                data.append('email', email);
                data.append('role_type', role_type);
                axios.post('/users', data)
                    .then(function(res) {
                        vm.loading = false;
                        // this.$emit('on-then', res);
                        vm.$page.props.jetstream.flash.banner = `Invitation sent successfully`; 
                    })
                    .catch(function(err) {
                        vm.loading = false;
                        console.error(err);
                        //if (error) this.$emit('on-catch', "Email already exist");
                        // this.$emit('on-catch', '');
                        vm.$page.props.jetstream.flash.bannerStyle = 'danger';
                        vm.$page.props.jetstream.flash.banner = 'You already sent invitation to this record.';
                    });
            }
        }
    }

</script>