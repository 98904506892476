<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="st-hidden-sorts">
            <p :class="sortClass('Coverage_Desc')" @click="sortBy('Coverage_Desc')">Coverage Desc</p>
            <p :class="sortClass('Limit1_Amount')" @click="sortBy('Limit1_Amount','number')">Limit 1 Amt</p>
            <p :class="sortClass('Limit2_Amount')" @click="sortBy('Limit2_Amount','number')">Limit 2 Amt</p>
        </div>
        <div class="simple-table" style="--cols:3">
            <p class="st-header first-col" :class="sortClass('Coverage_Desc')" @click="sortBy('Coverage_Desc')">Coverage Desc</p>
            <p class="st-header" :class="sortClass('Limit1_Amount')" @click="sortBy('Limit1_Amount','number')">Limit 1 Amt</p>
            <p class="st-header last-col" :class="sortClass('Limit2_Amount')" @click="sortBy('Limit2_Amount','number')">Limit 2 Amt</p>

            <template v-for="row in displayedList" :key="'coverage_' + row.id">
                <p class="first-col"><span class="st-hidden">Coverage Desc</span>{{ row.Coverage_Desc }}</p>
                <p><span class="st-hidden">Limit 1 Amt</span>{{ formatMoney(row.Limit1_Amount) }}</p>
                <p class="last-col"><span class="st-hidden">Limit 2 Amt</span>{{ formatMoney(row.Limit2_Amount) }}</p>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
        }
    }
</script>
