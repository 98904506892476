<template>
    <div class="error" v-if="!success && invoices.length == 0">
        <p style="font-weight: bold;">Please select at least 1 invoice to pay.</p>
    </div>
    <div class="credit-card" v-if="!success && invoices.length > 0">
        <form @submit.prevent="formSubmit" enctype="multipart/form-data">
            <div class="loader" v-if="loading"></div>
            <div v-else>
                <div class="flex flex-wrap gap-4">
                    <Address
                        v-model:first-name="form.first_name"
                        v-model:last-name="form.last_name"
                        v-model:address="form.address"
                        v-model:address-two="form.address2"
                        v-model:city="form.city"
                        v-model:state="form.state"
                        v-model:zip="form.zip"
                    />
                    <div class="group flex-grow">
                        <div class="credit-container">
                            <FontAwesomeIcon fixed-width :icon="visaIcon"/>
                            <FontAwesomeIcon fixed-width :icon="mastercardIcon"/>
                            <FontAwesomeIcon fixed-width :icon="amexIcon"/>
                            Credit Card
                        </div>
                        <label for="nameOnAccount"><span>Name on Card*</span>
                            <input type="text" name="nameOnAccount" v-model="form.name_on_account" placeholder="Name on your Credit Card"  class="field" required>
                        </label>
                        <label for="cardNumber"><span>Card*</span>
                            <input type="number" name="cardNumber" v-model="form.credit_card_number" placeholder="Your card number"  class="field" required>
                        </label>
                        <label for="month"><span>MM*</span><input type="number" placeholder="MM" v-model="form.credit_card_expiration_month" name="month" max="12" aria-label="Expirtation Month"  class="field" required></label>
                        <label for="year"><span>YY*</span><input type="number" placeholder="YY" v-model="form.credit_card_expiration_year" name="year" max="99" aria-label="Expiration Year"  class="field" required></label>
                        <label title="Three-digits code on the back of your card"><span>CVV*</span><input type="text" v-model="form.credit_card_security_code" max="9999" required  class="field"></label>
                    </div>
                </div>
                <div class="group flex flex-grow total-section justify-between align-center">
                    <div>
                        <span>Total {{formatMoney(invoicesTotal)}}</span><br>
                        <em>(Reflects 3% convenience fee)</em><br>
                    </div>
                    <button class="btn-blue flex-grow-0">Pay Now</button>
                </div>
            </div>
        </form>
    </div>
    <div class="success" v-if="processed && success">
        <h3>Thank you for your payment!</h3>
    </div>
    <div class="error" style="font-weight: bold;" v-if="processed && !success">
        <h3>Payment Declined.</h3>
        <p>There was a problem with trying to submit your payment.</p>
        <p>Please check your Credit Card Number, Expiration Date, and CVV Code.</p>
    </div>
</template>

<script>
    import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons'
    import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { library } from '@fortawesome/fontawesome-svg-core'
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import Address from './Address.vue'

    export default {
        mixins: [formattingMixin],
        components: {
            FontAwesomeIcon,
            faCcMastercard,
            faCcVisa,
            faCcAmex,
            Address,
            library
        },
        emits: ['refresh'],
        props: ['invoices'],
        data() {
            return {
                loading       : false,
                success       : false,
                processed: false,
                questionCircle: faQuestionCircle,
                visaIcon      : faCcVisa,
                mastercardIcon: faCcMastercard,
                amexIcon      : faCcAmex,
                form: {
                    payment_type                : 'Credit Card',
                    entity_ids                  : this.$page.props.user.login_entity_ids,
                    first_name                  : '',
                    last_name                   : '',
                    address                     : '',
                    address2                    : '',
                    city                        : '',
                    state                       : '',
                    zip                         : '',
                    credit_card_number          : '',
                    credit_card_expiration_month: '',
                    credit_card_expiration_year : '',
                    credit_card_security_code   : '',
                    total                       : '',
                    allInvoices                 : [],
                    name_on_account               : '',
                }
            }
        },
        computed: {
            invoicesTotal() {
                var total = 0.00;
                for (const listItem of this.invoices) {
                    total = total + listItem.Posted_Balance;
                }
                return parseFloat(total) * 1.03;
            }
        },
        methods: {
            formSubmit() {
                this.loading = true;
                this.form.total = this.invoicesTotal;
                this.form.allInvoices = this.invoices;
                axios.post('/payment', this.form)
                    .then( response =>  {
                        if(response.data.success){
                            this.loading = false;
                            this.success = true;
                            this.processed = true;
                            this.$emit('refresh', true);
                        } else {
                            this.loading = false;
                            this.success = false;
                            this.processed = true;
                        }
                })
            }
        }
    }
</script>

<style lang="scss">
.credit-card{

    form {
        display: flex;
        justify-content: space-between;
    }
    div.credit-container {
        padding-left: 20px;
    }
    .total-section {
        padding: 20px;
    }
    .group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
                0 3px 6px 0 rgba(0,0,0,0.08);
    border-radius: 4px;
    margin-bottom: 20px;

    }

    label {
    position: relative;

    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    }

    .group label {
    border-bottom: 2px solid #F0F5FA;
    }

    label > span {
    width: 140px;
    text-align: left;
    }

    .field {
    background: transparent;
    font-weight: 300;
    border: 0;

    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
    }

    .field::-webkit-input-placeholder { color: #CFD7E0; }
    .field::-moz-placeholder { color: #CFD7E0; }
}
</style>
