<template>
    <SimpleTableWrapper
        v-bind="wrapperBinder"
        v-on="wrapperEvents"
    >
        <div class="loader" v-if="loading"></div>

        <div class="st-hidden-sorts">
            <p :class="sortClass('description')" @click="sortBy('description')">Description</p>
            <p :class="sortClass('date_created')" @click="sortBy('date_created','date')">Date Created</p>
        </div>
        <div class="simple-table">
            <p class="st-header first-col" :class="sortClass('description')" @click="sortBy('description')">Description</p>
            <p class="st-header" :class="sortClass('date_created')" @click="sortBy('date_created','date')">Date Created</p>
            <p class="st-header last-col"></p>

            <template v-for="row in displayedList" :key="'documents_' + row.id">
                <p class="first-col"><span class="st-hidden">Description</span>{{ row.description }} - {{row.Doc_Class}}</p>
                <p><span class="st-hidden">Date Created</span>{{ formatDate(row.date_created) }}</p>
                <div class="last-col">
                    <span class="st-hidden">View</span>
                    <div class="st-buttons">
                        <button class="btn-teal" @click="openDocument(row.doc_id)" :title="`Opens the ${row.description} document in a new tab`"><FontAwesomeIcon :icon="eye"/><span>View</span></button>
                    </div>
                </div>
            </template>
        </div>
    </SimpleTableWrapper>
</template>

<script>
    import SimpleTableWrapper from '@/Components/SimpleTableWrapper.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faEye } from '@fortawesome/free-solid-svg-icons';
    import formattingMixin from '@/Mixins/formattingMixin.js'
    import tableMixin from '@/Mixins/tableMixin.js'

    export default {
        mixins: [tableMixin,formattingMixin],
        components: {
            SimpleTableWrapper,
            FontAwesomeIcon,
        },
        data() {
            return {
                eye: faEye,
                loading: false,
            }
        },
        methods: {
            openDocument(document_id){
                const data = new FormData();
                data.append('document_id', document_id);
                this.loading =  true;
                axios.post(route('openDocument'),data)
                    .then(response => {
                        this.loading =  false;
                        if(typeof response.data.document_url === 'undefined' || !typeof response.data.document_url){
                            alert(response.data.message)
                            return false;
                        }
                        window.open(response.data.document_url)
                    }).catch(error => {
                        this.loading = false,
                        alert(error.response.data.message)
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .simple-table {
        --custom-col-structure: repeat(2, minmax(min-content, auto)) max-content;

        .st-buttons {
            flex-wrap: nowrap;

            button {
                display: flex;
                align-items: center;
                span {
                    margin-left: .5rem;
                    flex-shrink: 0;
                }
            }
        }
    }
</style>