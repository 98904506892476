<template>
    <Card color="teal" title="My Invoices">
        <p class="-mt-4 mb-4">View the current invoices you have in place with VGM Insurance. Select an individual invoice to view further details.</p>

        <div class="loader" v-if="loading"></div>

        <form class="d-flex flex-wrap align-items-center justify-content-between" @submit.prevent="formSubmit" enctype="multipart/form-data" v-show="$page.props.user.role_type == 'AD' || $page.props.user.role_type == 'EM'">
            <div class="flex align-stretch" v-show="!hideSearch">
                <JetInput type="number" v-model="form.entity_id" placeholder="Enter Entity ID..." required max="999999999999999"/>
                <button  class="btn-blue flex-grow-0 rounded-l-none">Submit</button>
                <button class="btn btn-primary flex-grow-0 rounded-l-none ml-2" @click.prevent="reset()">Reset</button>
            </div>
        </form>

        <InvoicesTable
            v-if="search_data.length"
            :cols="$page.props.user.role_type!='CN'?7:6"
            respond-at="lg"
            class="mt-6"
            :data="search_data"
            :paid="paid"
            :pending="pending"
            :searchable-data="['Contract_Num', 'Posted_Balance', 'Due_Date', 'Contract_Number', 'Contract_Status', 'Effective_Date', 'Expiration_Date']"
            @selected-invoices="setInvoices($event)"
            search-placeholder="Search by Policy No..."
        />
        <p v-if="!search_data.length && form.entity_id && !loading" class="mt-4 bg-gray-200 p-4">No invoices found</p>
    </Card>
</template>

<script>
import InvoicesTable from '../Tables/InvoicesTable.vue'
import JetInput from '@/Jetstream/Input.vue'
import Card from '@/Components/Card.vue'

export default {
    components: {
        InvoicesTable,
        JetInput,
        Card,
    },
    emits: ['reset-entity', 'update-invoices'],
    props: {
        entityId: [Number, String],
        hideSearch: [Boolean],
        remount: [Number],
    },
    data() {
        return {
            form: {
                entity_id: null,
            },
            loading: false,
            search_data: [],
            selected_invoices: [],
            invoices: [],
            paid: [],
            pending: [],
            Entity_Ledger: [],

        };
    },
    mounted() {
        if (this.entityId) {
            this.form.entity_id = this.entityId;
            this.formSubmit();
        }
    },
    watch: {
        remount: function(newVal, oldVal) {
            this.formSubmit();
        },
    },
    methods: {
        formSubmit() {
            this.loading = true,
            axios.post('/invoices/search', this.form)
                .then( response =>  {
                    this.loading = false,
                    this.search_data = [];
                    // Loop through array result
                    response.data.search_results.forEach(result => {
                        // Freeze (make readonly) every object in the array (this boosts Performance A LOT)
                        this.search_data.push(Object.freeze(result));
                        this.invoices.push(result.Contract_ID);
                        this.Entity_Ledger.push({'ledger': result.Entity_Ledger, 'invoice': result.Contract_ID});
                    });
                    this.fetchPayments(this.invoices, this.Entity_Ledger);
            });
        },
        reset() {
            console.log(this);
            this.form.entity_id = '';
            this.$emit('reset-entity');
        },
        setInvoices($invoices)
        {
            this.selected_invoices = $invoices;
            this.$emit('update-invoices', this.selected_invoices);
        },
        fetchPayments($invoices, $entityLedger)
        {
            axios.post('/fetch-payments',
                {
                    'invoices': $invoices,
                    'Entity_Ledger': $entityLedger
                })
                .then( response =>  {
                    this.loading = false;
                   
                    // Loop through paid
                    response.data.paid.forEach(result => {
                        this.paid.push(result);
                    });
                    // Loop through pending
                    response.data.pending.forEach(result => {
                        this.pending.push(result);
                    });
            });
        }
    }
}
</script>
