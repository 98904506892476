<template>
    <AppLayout title="Reports">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'Reports']"/>
        </template>

        <div class="program-grid md:grid -my-4 md:my-0">
            <Card color="blue-500" @click="exportActiveUsersReport()" title="Active Users Report" cta-cover>
                <p class="text-sm">Click here to download the Active Users Report.</p>
            </Card>

            <Card color="blue-500" @click="exportInvitationsReport()" title="Invitations Report " cta-cover>
                <p class="text-sm">Click here to download the Invitations Report.</p>
            </Card>
        </div>
    </AppLayout>
</template>

<script>
import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
import AppLayout from '@/Layouts/AppLayout.vue'
import Card from '@/Components/Card.vue'
import { excelParser } from "@/Export/excel-parser";

export default {
    components: {
        Breadcrumbs,
        AppLayout,
        Card,
        excelParser,
    },
    methods: {
        vins(url) {
            return `https://www.vgminsurance.com/${url}`;
        },
        programs(url) {
            return this.vins(`programs/${url}`);
        },
        resources(url) {
            return this.vins(`resources/${url}`);
        },
        exportActiveUsersReport() {
            axios({
                method: 'get',
                url: route('reports.activeUsersData'),
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: null
            })
            .then(response => {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const fileName = 'ActiveUsersReport_' + mm + '-' + dd + '-' + yyyy;

                excelParser().exportDataFromJSON(response['data'], fileName, 'csv');
            })

        },
        exportInvitationsReport() {
            axios({
                method: 'get',
                url: route('reports.invitationsData'),
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: null
            })
            .then(response => {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const fileName = 'InvitationsReport_' + mm + '-' + dd + '-' + yyyy;

                excelParser().exportDataFromJSON(response['data'], fileName, 'csv');
            })

        },
        
    }
}
</script>

<style lang="scss" scoped>
    @import '../../../scss/_mixins.scss';

    .program-grid {
        grid-auto-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-auto-flow: column dense;
        grid-gap: 2rem;

        > * {
            margin: 2rem 0;

            @include desktop {
                margin: 0;
            }
        }
    }
</style>