<template>
    <form class="-mx-2 flex flex-col justify-between h-full" @submit.prevent="updateProfileInformation">
        <div>
            <!-- Profile Photo -->
            <div class="p-2" v-if="$page.props.jetstream.managesProfilePhotos">
                <!-- Profile Photo File Input -->
                <input type="file" class="hidden" ref="photo" @change="updatePhotoPreview" />
                <JetLabel for="photo" value="Photo" />
                <!-- Profile Photo -->
                <div class="inline-flex flex-wrap items-center -mx-2 -mb-2">
                    <div class="m-2 flex justify-center items-center" style="flex-grow:1">
                        <img :src="profileImageSrc" :alt="user.name" class="rounded-full h-20 w-20 object-cover">
                    </div>
                    <div class="flex flex-wrap" style="flex-grow:9999">
                        <button class="m-2 flex-grow justify-center text-center btn-outline-gray" type="button" @click.prevent="selectNewPhoto">Select A New Photo</button>
                        <button class="m-2 flex-grow justify-center text-center btn-outline-gray" type="button" @click.prevent="deletePhoto" v-if="user.profile_photo_path">Remove Photo</button>
                        <JetInputError class="m-2" :message="form.errors.photo" />
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap">
                <!-- Name -->
                <div class="flex flex-wrap flex-grow">
                    <div class="p-2 flex-grow">
                        <JetLabel for="first_name" value="First Name" />
                        <JetInput id="first_name" type="text" class="mt-1 block w-full" v-model="form.first_name" autocomplete="name" />
                        <JetInputError :message="form.errors.first_name" class="mt-2" />
                    </div>
                    <div class="p-2 flex-grow">
                        <JetLabel for="last_name" value="Last Name" />
                        <JetInput id="last_name" type="text" class="mt-1 block w-full" v-model="form.last_name" autocomplete="name" />
                        <JetInputError :message="form.errors.last_name" class="mt-2" />
                    </div>
                </div>
                <!-- Email -->
                <div class="p-2 w-full">
                    <JetLabel for="email" value="Email" />
                    <JetInput id="email" :readonly="form.email" type="email" class="mt-1 block w-full" v-model="form.email" />
                    <JetInputError :message="form.errors.email" class="mt-2" />
                </div>
            </div>
        </div>

        <div class="pt-2 px-2 w-full flex justify-end items-center">
            <JetActionMessage :on="form.recentlySuccessful" class="font-bold text-green-500" :class="{'mr-3':form.recentlySuccessful}">Saved!</JetActionMessage>
            <button class="btn-blue flex-grow-0" :class="{'disabled':form.processing}">Save</button>
        </div>
    </form>
</template>

<script>
    import JetActionMessage from '@/Jetstream/ActionMessage.vue'
    import JetInputError from '@/Jetstream/InputError.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetActionMessage,
            JetInputError,
            JetInput,
            JetLabel,
        },
        props: ['user'],
        data() {
            return {
                form: this.$inertia.form({
                    _method: 'PUT',
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    email: this.user.email,
                    photo: null,
                }),
                photoPreview: null,
            }
        },
        computed: {
            profileImageSrc() {
                let path = this.user.profile_photo_path;
                return this.photoPreview || (path ? `/storage/profile-photos/${path}` : this.user.profile_photo_url);
            }
        },
        methods: {
            updateProfileInformation() {
                if (this.$refs.photo) {
                    this.form.photo = this.$refs.photo.files[0]
                }

                // This updates the photo path to a value, but doesn't actually handle saving the image to that path.
                // The image, on upload and save, will always be broken.
                this.form.post(route('user-profile-information.update'), {
                    errorBag: 'updateProfileInformation',
                    preserveScroll: true,
                    onSuccess: () => (this.clearPhotoFileInput()),
                });
            },
            selectNewPhoto() {
                this.$refs.photo.click();
            },
            updatePhotoPreview() {
                const photo = this.$refs.photo.files[0];
                if (!photo) return;
                let vm = this;
                const reader = new FileReader();
                reader.onload = (e) => vm.photoPreview = e.target.result;
                reader.readAsDataURL(photo);
            },
            deletePhoto() {
                this.$inertia.delete(route('current-user-photo.destroy'), {
                    preserveScroll: true,
                    onSuccess: () => {
                        // This doesn't do anything, photoPreview is always null unless you've just uploaded something new without saving.
                        // This doesn't clear the preview.
                        this.photoPreview = null;
                        this.clearPhotoFileInput();
                    },
                });
            },
            clearPhotoFileInput() {
                if (this.$refs.photo?.value) {
                    this.$refs.photo.value = null;
                }
            },
        },
    }
</script>
