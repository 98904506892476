<template>
    <form enctype="multipart/form-data" ref="form" @submit.prevent="submitForm">
        <div class="loader" v-if="loading"></div>
        <template v-else>
            <h3 class="text-blue-500">Additional Insured</h3>
            <div v-if="validate_msg != ''" class="bg-green-100 p-2">
                <p class="text-green-500 my-0">{{validate_msg}}</p>
            </div>
            <div class="bg-red-100 p-2">
                <p class="text-red-500 my-0">{{error_msg}}</p>
            </div>
            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex items-center">
                    <JetCheckbox id="primary_non_contributory" name="primary_non_contributory" value="Primary Non-Contributory"/>
                    <JetLabel for="primary_non_contributory" value="Primary Non-Contributory" class="p-2" />
                </div>
                <div class="m-2 flex items-center">
                    <JetCheckbox id="waiver_of_subrogation" name="waiver_of_subrogation" value="Waiver of Subrogation"/>
                    <JetLabel for="waiver_of_subrogation" value="Waiver of Subrogation" class="p-2" />
                </div>
            </div>
            <div class="md:flex -mb-2 -mx-2">
                <div class="m-2 flex-grow">
                    <JetLabel for="requester_name" value="Requester Full Name" />
                    <JetInput name="requester_name" type="text" class="mt-1 block w-full" v-model="form.requester_name" required/>
                </div>
                <div class="m-2 flex-grow">
                    <JetLabel for="requester_email" value="Requester Email" />
                    <JetInput name="requester_email" type="email" class="mt-1 block w-full" v-model="form.requester_email" required/>
                </div>
                <div class="m-2 flex-grow">
                    <SimpleDate v-model="form.date" label="Effective Date of Change" name="effective_date_of_change" required/>
                </div>
            </div>
             <div class="md:flex -mb-2 -mx-2">
                <div class="flex-grow">
                    <div class="m-2">
                        <JetLabel for="add_insured_full_name" value="Additional Insured Full Name" />
                        <JetInput name="add_insured_full_name" type="text" class="mt-1 block w-full" required/>
                    </div> 
                </div>
            </div>
            <AddressInputGroup whose-address="Additional Insured"/>
            <div class="md:flex -mb-2 -mx-2">
                <div class="flex-grow">       
                    <div class="m-2">
                        <JetLabel for="relationship_add_insured" value="Relationship Of Additional Insured" />
                        <JetInput name="relationship_add_insured" type="text" class="mt-1 block w-full" required/>
                    </div>
                </div>
                <div class="m-2 flex-grow flex flex-col">
                    <JetLabel for="notes" value="Notes" />
                    <textarea name="message" class="flex-grow border-gray-400 focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50 shadow-sm bg-white mt-1 block w-full" placeholder="Enter Message" required></textarea>
                </div>
            </div>
            <div class="mt-2">
                <JetLabel for="loss_payee_cdocument" value="Upload Document" />
                <SimpleFileUploader id="loss_payee_cdocument" v-model="form.files" />
            </div>
            <div class="flex flex-wrap mt-2 -mx-2 -mb-2">
                <button class="btn-green m-2">Submit</button>
                <button @click.prevent="closeModal()" class="btn-outline-gray m-2">Cancel</button>
                <button @click.prevent="reset()" class="btn-outline-gray m-2">Reset</button>
            </div>
        </template>
    </form>
</template>

<script>
import SimpleFileUploader from '@/Components/FormElements/SimpleFileUploader.vue'
import AddressInputGroup from '@/Components/AddressInputGroup.vue'
import SimpleDate from '@/Components/FormElements/SimpleDate.vue'
import formProcessingMixin from '@/Mixins/formProcessingMixin.js'
import JetCheckbox from '@/Jetstream/Checkbox.vue'
import JetLabel from '@/Jetstream/Label.vue'
import JetInput from '@/Jetstream/Input.vue'

export default {
    mixins: [formProcessingMixin],
    props: ["pdata", "resetFormTrigger", "visible"],
    components: {
        SimpleFileUploader,
        AddressInputGroup,
        JetCheckbox,
        SimpleDate,
        JetLabel,
        JetInput,
    },
    data() {
        return {
            // just for passing user data by default, not for using with submission
            form: {
                requester_name: '',
                requester_email: '',
                date: '',
                files: []
            },
            validate_msg: '',
            loading: false,
            error_msg: '',
        }
    },
    methods: {
        submitForm(event) {
            this.submit(event, 'submitAdditionalInsuredForm', this.additionalData, {name: 'files', list: this.form.files});
        },
        initWithUserData() {
            let u = this.$page.props.user;
            this.form.requester_name = `${u.first_name} ${u.last_name}`;
            this.form.requester_email = u.email;
        }
    },
    computed: {
        additionalData() {
            return [
                { key: 'account_manager_email', value: this.pdata.account_manager_email },
                { key: 'account_manager_name',  value: this.pdata.account_manager_name },
                { key: 'insured_name',          value: this.pdata.insured_name }
            ]
        }
    },
    watch: {
        resetFormTrigger() {
            this.reset();
        },
        visible(n) {
            if (n) this.initWithUserData();
        }
    }
}
</script>