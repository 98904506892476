<template>
    <div class="group flex-grow">
        <label for="firstName">
            <span>First Name*</span>
            <input id="firstName" class="field" name="firstName" :value="firstName" required @input="$emit('update:firstName', $event.target.value)" />
        </label>
        <label for="lastName">
            <span>Last Name*</span>
            <input id="lastName" class="field" name="lastName" :value="lastName" required @input="$emit('update:lastName', $event.target.value)" />
        </label>
        <label for="address">
            <span>Address*</span>
            <input id="address" class="field" name="address" :value="address" required @input="$emit('update:address', $event.target.value)" />
        </label>
        <label for="addressTwo">
            <span>Address 2</span>
            <input id="addressTwo" class="field" name="addressTwo" :value="addressTwo" @input="$emit('update:addressTwo', $event.target.value)" />
        </label>

        <label>
            <span>City*</span>
            <input id="city" class="field" name="city" :value="city" required @input="$emit('update:city', $event.target.value)" />
        </label>
        <label>
            <span>State*</span>
            <select name="state" id="state" :value="state" required @input="$emit('update:state', $event.target.value)">
                <option disabled value="">-- Select a state</option>
                <option v-for="state in stateOptions" :key="state.value" :value="state.value">
                    {{ state.name}}
                </option>
            </select>
        </label>
        <label>
            <span>Zip*</span>
            <input id="zip" class="field" name="zip" :value="zip" required @input="$emit('update:zip', $event.target.value)" />
        </label>
    </div>
</template>

<script>
    import SimpleSelect from '@/Components/FormElements/SimpleSelect.vue'
    import formattingMixin from '@/Mixins/formattingMixin.js'

    export default {
        mixins: [formattingMixin],
        components: {
            SimpleSelect,
        },
        props: {
            firstName   :String,
            lastName    :String,
            address     :String,
            addressTwo :String,
            city        :String,
            state       :String,
            zip         :String,
        },
        emits: ['update:firstName', 'update:lastName', 'update:address', 'update:addressTwo', 'update:city','update:state','update:zip'],
        data() {
            return {
                stateOptions: [
                {name:"AK",value:"AK"},
                {name:"AL",value:"AL"},
                {name:"AR",value:"AR"},
                {name:"AZ",value:"AZ"},
                {name:"CA",value:"CA"},
                {name:"CO",value:"CO"},
                {name:"CT",value:"CT"},
                {name:"DE",value:"DE"},
                {name:"FL",value:"FL"},
                {name:"GA",value:"GA"},
                {name:"HI",value:"HI"},
                {name:"IA",value:"IA"},
                {name:"ID",value:"ID"},
                {name:"IL",value:"IL"},
                {name:"IN",value:"IN"},
                {name:"KS",value:"KS"},
                {name:"KY",value:"KY"},
                {name:"LA",value:"LA"},
                {name:"MA",value:"MA"},
                {name:"MD",value:"MD"},
                {name:"ME",value:"ME"},
                {name:"MI",value:"MI"},
                {name:"MN",value:"MN"},
                {name:"MO",value:"MO"},
                {name:"MS",value:"MS"},
                {name:"MT",value:"MT"},
                {name:"NC",value:"NC"},
                {name:"ND",value:"ND"},
                {name:"NE",value:"NE"},
                {name:"NH",value:"NH"},
                {name:"NJ",value:"NJ"},
                {name:"NM",value:"NM"},
                {name:"NV",value:"NV"},
                {name:"NY",value:"NY"},
                {name:"OH",value:"OH"},
                {name:"OK",value:"OK"},
                {name:"OR",value:"OR"},
                {name:"PA",value:"PA"},
                {name:"RI",value:"RI"},
                {name:"SC",value:"SC"},
                {name:"SD",value:"SD"},
                {name:"TN",value:"TN"},
                {name:"TX",value:"TX"},
                {name:"UT",value:"UT"},
                {name:"VA",value:"VA"},
                {name:"VT",value:"VT"},
                {name:"WA",value:"WA"},
                {name:"WI",value:"WI"},
                {name:"WV",value:"WV"},
                {name:"WY",value:"WY"},
                ],
            }
        }
    }
</script>

<style>
form {
  margin: 20px 0;
}

.group {
  background: white;
  box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10),
              0 3px 6px 0 rgba(0,0,0,0.08);
  border-radius: 4px;
  margin-bottom: 20px;
}

label {
  position: relative;

  font-weight: 300;
  height: 40px;
  line-height: 40px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
}

.group label {
  border-bottom: 2px solid #F0F5FA;
}

label > span {
  width: 120px;
  text-align: left;
}

.field {
  background: transparent;
  font-weight: 300;
  border: 0;

  outline: none;
  flex: 1;
  padding-right: 10px;
  padding-left: 10px;
  cursor: text;
}

.field::-webkit-input-placeholder { color: #CFD7E0; }
.field::-moz-placeholder { color: #CFD7E0; }

</style>
