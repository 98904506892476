<template>
    <AppLayout title="Pay My Bill">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'My View','Pay My Bill']"/>
        </template>

        <InvoicesSection
            :entityId="entity_ids"
            :hideSearch="hide_search"
            :remount="remount"
            @update-invoices="selectedInvoices($event)"
        />
        <div class="md:grid md:grid-cols-2">
            <ConfirmSection
                :invoices="selected_invoices"
                @update-invoices="selectedInvoices($event)"
            />
            <PaymentMethodSection
                :invoices="selected_invoices"
                @paymentMethod="updatePaymentMethod($event)"
                @refresh="refresh($event)"
            />
        </div>

    </AppLayout>
</template>

<script>
    import PaymentMethodSection from './Sections/PaymentMethodSection.vue'
    import InvoicesSection from './Sections/InvoicesSection.vue'
    import ConfirmSection from './Sections/ConfirmSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        components: {
            PaymentMethodSection,
            InvoicesSection,
            ConfirmSection,
            Breadcrumbs,
            AppLayout,
        },
        data() {
            return {
                invoices         : [],
                selected_invoices: [],
                payment_method   : null,
                hide_search      : false,
                remount          : 0,
            }
        },
        computed: {
            entity_ids() {
                //if (this.$page.props.user.role_type == 'AD') return 17089200;
                let id = this.$page.props.user.login_entity_ids;
                if(this.$page.props.user.role_type == 'AD'){// || this.$page.props.user.role_type == 'EM'){
                    return ''
                } else {
                    this.hide_search = true;
                    return id !== 'NA' ? id : '';
                }
            },
            entityId() {
                return this.entity_ids;
            }
        },
        methods: {
            refresh(event)
            {
                this.selected_invoices=[];
                this.remount ++;
            },
            selectedInvoices($event){
                this.selected_invoices = $event;
            },
            updatePaymentMethod($event){
                this.payment_method = $event;
            }
        }
    }
</script>
