<template>
    <div class="app" :class="{expanded: expandedNav}">
        <Head :title="title" />

        <JetBanner />

        <div class="loader" v-if="loading"></div>

        <div class="app-wrapper">
            <AppSidebar/>

            <div class="hamburger" @click="expandToggle()">
                <FontAwesomeIcon v-if="!expandedNav" fixed-width :icon="bars"/>
                <FontAwesomeIcon v-else fixed-width :icon="times"/>
            </div>

            <div class="content-section">
                <div class="content-top">
                    <div class="account-actions">

                        <div v-if="$page.props.user.isImpersonating" >
                            <button class="text-blue-500" @click="exitImpersonation()">
                                <FontAwesomeIcon :icon="mask" />
                                <span>Exit Impersonation</span>
                            </button>
                        </div>

                        <div class="relative">
                            <!-- Teams Dropdown -->
                            <JetDropdown align="right" width="max" v-if="$page.props.jetstream.hasTeamFeatures">
                                <template #trigger>
                                    <span class="inline-flex rounded-md">
                                        <button class="text-blue-500">
                                            <FontAwesomeIcon :icon="user" />
                                            <span>{{ $page.props.user.first_name }} {{$page.props.user.last_name!=null?$page.props.user.last_name:''}}
                                           </span>
                                        </button>
                                    </span>
                                </template>

                                <template #content>
                                    <div class="w-max">
                                        <template v-if="$page.props.jetstream.hasTeamFeatures">
                                            <div class="block px-4 py-2 text-xs text-gray-400">
                                                Manage Account
                                            </div>

                                            <JetDropdownLink :href="route('profile.show')">
                                                Profile
                                            </JetDropdownLink>

                                             <form @submit.prevent="logout">
                                                <JetDropdownLink as="button">
                                                    Log Out
                                                </JetDropdownLink>
                                            </form>

                                            <a v-if="$page.props.user.role_type == 'AD'" href="/logs" class="block w-full px-4 py-2 text-sm leading-5 text-gray-900 text-left hover:bg-gray-100 hover:text-blue-500 focus:outline-none focus:bg-gray-100 transition">View Error Logs</a>

                                        </template>
                                    </div>
                                </template>
                            </JetDropdown>
                        </div>
                    </div>

                    <header>
                        <h2 class="header-greeting">Hello, {{ $page.props.user.first_name }}</h2>
                        <!-- <p class="last-visited" v-if="route().current('dashboard') && $page.props.user.last_login_date">Last Visited: {{ formatLastVistedDate }}</p> -->
                        <!-- <slot name="breadcrumbs" v-if="$slots.breadcrumbs"></slot> -->
                    </header>
                </div>

                <main>
                    <slot></slot>
                </main>
            </div>

            <AppFooter />
        </div>
    </div>
</template>

<script>
    import { faBars, faTimes, faUser, faMask } from '@fortawesome/free-solid-svg-icons';
    import JetApplicationLogo from '@/Jetstream/ApplicationLogo.vue'
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import JetDropdownLink from '@/Jetstream/DropdownLink.vue'
    import JetDropdown from '@/Jetstream/Dropdown.vue'
    import AppSidebar from '@/Layouts/AppSidebar.vue'
    import AppFooter from '@/Layouts/AppFooter.vue'
    import { Head } from '@inertiajs/inertia-vue3'
    import JetBanner from '@/Jetstream/Banner.vue'
    import moment from 'moment';

    export default {
        props: {
            title: String,
        },
        components: {
            JetApplicationLogo,
            FontAwesomeIcon,
            JetDropdownLink,
            JetDropdown,
            AppSidebar,
            JetBanner,
            AppFooter,
            Head,
        },
        data() {
            return {
                showingNavigationDropdown: false,
                isImpersonating: false, // This hides and shows the button to exit impersonation
                expandedNav: false,
                loading: false,
                times: faTimes,
                bars: faBars,
                user: faUser,
                mask: faMask,
            }
        },
        watch: {
            expandedNav(n,o) {
                if (!n) document.body.style.overflow = '';
            }
        },
        computed: {
            formatLastVistedDate() {
                return moment(this.$page.props.user.last_login_date).format('MMMM D, YYYY');
            },
            formatCurrentComponent() {
                return this.$page.component;
            }
        },
        mounted() {
            // Check if impersonating
            // If impersonating, set isImpersonating = true;
        },
        methods: {
            logout() {
                this.$inertia.post(route('logout'));
            },
            expandToggle() {
                this.expandedNav = !this.expandedNav;
                // document.body.style.overflow = this.expandedNav ? 'hidden' : '';
            },
            exitImpersonation(data) {
                this.isImpersonating = false;
                this.loading = true;
                axios
                    .post(route('exitimpersonation'),data)
                    .then(response => {
                        this.$inertia.visit(route('dashboard'));
                    })

                // Plus set it in the session or wherever we plan to set it
                this.$inertia.visit(route('users.index'));
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .app-wrapper {
        overflow: auto;
        height: 100%;
        display: grid;
        min-height: 100vh;
        grid-template:
            "nav content" max-content
            "footer footer" max-content
            / max-content 1fr;

        .hamburger {
            @include fluid-size(18px, 20px, font-size);
            font-weight: bold;
            position: relative;
            color: var(--gray-700);
            overflow: hidden;
            position: fixed;
            bottom: 1rem;
            left: calc(50% - 8.5px); // The width of the average scrollbar
            transform: translate(-50%, 0);
            transition: 200ms ease-out;
            background-color: white;
            border-radius: 50rem;
            box-shadow: 2px 0 5px rgba(0,0,0,.1);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;

            @include not-desktop {
                height: 40px;
                width: 40px;
            }

            @include desktop {
                height: 0;
                width: 0;
            }
        }

        .content-section {
            grid-area: content;
            overflow: auto;
            z-index: 0;

            @include not-desktop {
                .app.expanded & { overflow: hidden; }
            }

            .content-top {
                display: flex;
                flex-direction: column;

                header {
                    align-self: flex-start;
                    @include fluid-size(16px, 32px, padding-right);
                    @include fluid-size(16px, 32px, padding-left);
                    @include fluid-size(16px, 32px, padding-bottom);

                    .header-greeting {
                        @include fluid-size(20px, 42px, font-size);
                        color: var(--blue-500);
                        font-weight: 700;
                        line-height: 1;
                    }
                }

                .account-actions {
                    align-self: flex-end;
                    display: flex;
                    align-items: center;
                    @include fluid-size(0px, 16px, padding);

                    button {
                        @include fluid-size(14px, 18px, font-size);
                        display: flex;
                        align-items: center;
                        font-weight: 800;
                        margin: .5rem;
                        text-align: left;
                        line-height: 1;

                        svg { @include fluid-size(18px, 24px, font-size) }

                        svg, span {
                            margin: .25rem;
                        }
                    }
                }
            }

            main {
                @include fluid-size(16px, 32px, padding-left);
                @include fluid-size(16px, 32px, padding-right);
                @include fluid-size(16px, 32px, padding-bottom);
                @include fluid-size(32px, 48px, padding-top);
            }
        }
    }
</style>