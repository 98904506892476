<template>
    <AppLayout title="Account Clearance">
        <template #breadcrumbs>
            <Breadcrumbs :path="[{name:'Dashboard',route:'dashboard'},'My View','Account Clearance']"/>
        </template>
        <BondsSearchSection
            v-if="entityId == 'NA' && ['EM','AD','BR'].some(r => $page.props.user.role_type == r)"
            @selected-entity="setCompany($event)"
        />
        <BondsSection
            v-else
            :entity-id="entityId"
            @reset-entity="clearEntityId()"
        />
    </AppLayout>
</template>

<script>
    import BondsSection from './Sections/BondsSection.vue'
    import BondsSearchSection from './Sections/AccountClearanceSearchSection.vue'
    import Breadcrumbs from '@/Layouts/Breadcrumbs.vue'
    import AppLayout from '@/Layouts/AppLayout.vue'

    export default {
        components: {
            BondsSection,
            BondsSearchSection,
            Breadcrumbs,
            AppLayout,
        },
        data() {
            return {
                company_entity_id: null,
            }
        },
        computed: {
            loginEntityId() {
                //if (this.$page.props.user.role_type == 'AD') return 33172100;
                let id = this.$page.props.user.login_entity_ids;
                if(this.$page.props.user.role_type == 'AD' || this.$page.props.user.role_type == 'EM' || this.$page.props.user.role_type == 'BR'){
                    return 'NA'
                } else {
                    return id !== 'NA' ? id : '';
                }
            },
            entityId() {
                return this.company_entity_id || this.loginEntityId;
            },
            userType() {
                return this.$page.props.user.role_type;
            },
        },
        methods: {
            setCompany(entity_id) {
                this.company_entity_id = entity_id;
            },
            clearEntityId() {
                this.company_entity_id = null;
            }
        }
    }
</script>
