<template>
    <Card color="blue-500" title="My Claims">
        <p class="-mt-4 mb-4" v-if="search_data.length">{{ userType!='CN' ? 'Select an individual claim to view full details. ' : '' }}Click on the filters at the top of the columns to organize and sort {{ userType!='CN' ? 'the' : 'your' }} claims.</p>

        <div class="loader" v-if="loading"></div>

        <form @submit.prevent="formSubmit" enctype="multipart/form-data" v-show="$page.props.user.role_type == 'AD' || $page.props.user.role_type == 'EM'">
            <div class="flex align-stretch">
                <JetInput type="text" v-model="form.entity_id" placeholder="Enter Entity ID..." required maxlength="15"/>
                <button class="btn-blue flex-grow-0 rounded-l-none">Submit</button>
                <button class="btn btn-primary flex-grow-0 rounded-l-none ml-2" @click.prevent="reset()">Reset</button>
            </div>
        </form>

        <ClaimsTable
            v-if="search_data.length"
            :user-type="userType"
            class="mt-6"
            :data="search_data"
            respond-at="xl"
            :searchable-data="searchableData"
        />
        <p v-if="!search_data.length && form.entity_id && !loading" class="mt-4 bg-gray-200 p-4">No claims found</p>
        <p class="text-gray-400" v-else-if="!search_data.length && !loading">No claims to display</p>
    </Card>
</template>

<script>
    import ClaimServicingSection from '../Details/Sections/ClaimServicingSection.vue'
    import ClaimsTable from '../Tables/ClaimsTable.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import Card from '@/Components/Card.vue'

    export default {
        components: {
            ClaimServicingSection,
            ClaimsTable,
            JetInput,
            Card,
        },
        emits: ['reset-entity'],
        props: {
            entityId: [Number, String],
            claims_data: [Array],
        },
        data() {
            return {
                form: {
                    entity_id: null,
                },
                loading: false,
                search_data: [],
            };
        },
        mounted() {
            if (this.entityId) {
                this.form.entity_id = this.entityId;
                this.formSubmit();
            }
            if (this.claims_data) {
                this.search_data = this.claims_data;
            }
        },
        computed: {
            userType() {
                return this.$page.props.user.role_type;
            },
            searchableData() {
                let arr = [
                    "Policy_number",
                    "Claim_Number",
                    "Claim_Status",
                    "Claimant",
                    "Loss_Short_Desc",
                ];

                if (this.userType=='AD') arr.push('Insured_name');

                return arr;
            }
        },
        methods: {
            formSubmit() {
                this.loading = true,
                axios.post('/claims/search', this.form)
                    .then( response =>  {
                    this.loading = false,
                    this.search_data = response.data.search_results
                }).catch(function (error) {
                    this.loading = false,
                    alert(error.response.data);
                });
            },
            reset() {
                this.$emit('reset-entity');
            }
        }
    }
</script>
