<template>
    <Head title="Secure Area" />

    <JetAuthenticationCard>
        <template #logo>
            <JetAuthenticationCardLogo />
        </template>

        <div class="mb-4 text-sm text-gray-600">
            This is a secure area of the application. Please confirm your password before continuing.
        </div>

        <JetValidationErrors class="mb-4" />

        <form @submit.prevent="submit">
            <div>
                <SimplePassword v-model="form.password" autocomplete="current-password" autofocus />
            </div>

            <div class="flex justify-end mt-4">
                <JetButton class="ml-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                    Confirm
                </JetButton>
            </div>
        </form>
    </JetAuthenticationCard>
</template>

<script>
    import JetAuthenticationCardLogo from '@/Jetstream/AuthenticationCardLogo.vue'
    import SimplePassword from '@/Components/FormElements/SimplePassword.vue'
    import JetAuthenticationCard from '@/Jetstream/AuthenticationCard.vue'
    import JetValidationErrors from '@/Jetstream/ValidationErrors.vue'
    import { Head } from '@inertiajs/inertia-vue3';
    import JetButton from '@/Jetstream/Button.vue'
    import JetInput from '@/Jetstream/Input.vue'
    import JetLabel from '@/Jetstream/Label.vue'

    export default {
        components: {
            JetAuthenticationCardLogo,
            JetAuthenticationCard,
            JetValidationErrors,
            SimplePassword,
            JetButton,
            JetInput,
            JetLabel,
            Head,
        },

        data() {
            return {
                form: this.$inertia.form({
                    password: '',
                })
            }
        },

        methods: {
            submit() {
                this.form.post(this.route('password.confirm'), {
                    onFinish: () => this.form.reset(),
                })
            }
        }
    }
</script>
