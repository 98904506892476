<template>
    <div class="splash-background" :class="{animate: animate}">
        <div class="green"></div>
        <div class="teal"></div>
        <div class="navy"></div>
    </div>
</template>

<script>
export default {
    name: "SplashScreen",
    props: {
        animate: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/_mixins.scss';

    .splash-background {
        --gap-width: 2vw;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        display: grid;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;

        @include desktop {
            grid-template:
                "tl tm tr" 1fr
                "bl bm br" 1fr
                / 1.5fr 1.5fr 7fr;
        }

        @include not-desktop {
            grid-template:
                "tl tm tr" 1fr
                "bl bm br" 1fr
                / 1.5fr 1.5fr 3fr;
        }

        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: -0.5rem;
            left: 0;
        }
    }

    .navy {
        grid-area: tl-start / tl-start / br-end / br-end;
        position: relative;
        overflow: hidden;
        transform: translateX(var(--gap-width));

        @include desktop { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%); }
        @include not-desktop { clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 100%); }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgb(13, 45, 108);
        }

        &:before {
            content: "";
            background-image: url('/images/LoginBuildingImg.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            opacity: 0;
            left: 0;
        }
    }

    .green {
        grid-area: tl-start / tl-start / bm-end / bm-end;
        position: relative;
        clip-path: polygon(0% 0%, 0% 100%, 50% 50%);

        &:before {
            content: "";
            background-color: rgb(132, 189, 0);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    .teal {
        grid-area: bl-start / bl-start / bm-end / bm-end;
        position: relative;
        clip-path: polygon(calc(50% - var(--gap-width)) 0%, calc(100% - var(--gap-width)) 100%, 0% 100%);

        &:before {
            content: "";
            position: absolute;
            background-color: rgb(107, 202, 186);
            clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
            transform: translateX(var(--gap-width));
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    $dur: 1s;
    $del: 0.5s;
    $ease: cubic-bezier(0.25, 1, 0.5, 1);

    .splash-background:not(.animate) {
        .teal {
            opacity: 1;
            transform: translate(0, 0);
        }
        .green {
            opacity: 1;
            transform: translate(calc(-1 * var(--gap-width)), 0);
        }
        .navy:after {
            animation: fadeOutNavy 2s $ease both;
            height: 100%;
        }
        .navy:before {
            opacity: 1;
            transform: scale(1.1);
        }
    }

    .splash-background.animate {
        .teal { animation: slideInTeal $dur $ease forwards; }
        .green { animation: slideInGreen $dur $ease $del both; }
        .navy:before { animation: slowZoomImage 12s $ease ($del * 5) forwards; }
        .navy:after { animation: slideInNavy $dur $ease ($del * 2) both, fadeOutNavy 6s $ease ($del * 5) both;}
    }


    @keyframes slideInTeal {
        0% {
            opacity: 0;
            transform: translate(calc(50vh / 12 * 5), 50vh);
        }
        100% {
            opacity: 1;
            transform: translate(0, 0);
        }
    }

    @keyframes slideInGreen {
        0% {
            opacity: 0;
            transform: translate(calc((-50vh / 12 * 5) - var(--gap-width)), 50vh);
        }
        100% {
            opacity: 1;
            transform: translate(calc(-1 * var(--gap-width)), 0);
        }
    }

    @keyframes slideInNavy {
        0% {
            opacity: 0;
            height: 0;
        }
        100% {
            opacity: 1;
            height: 100%;
        }
    }

    @keyframes fadeOutNavy {
        0% { opacity: 1; }
        100% { opacity: 0.5; }
    }

    @keyframes slowZoomImage {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        100% {
            opacity: 1;
            transform: scale(1.1);
        }
    }
</style>